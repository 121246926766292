import React, {
	createContext,
	useReducer,
	useContext,
	useEffect,
	useRef
} from "react";

export enum EnvVariable {
	Tenant = "REACT_APP_TENANT",
	Design = "REACT_APP_DESIGN",
	Locale = "REACT_APP_LOCALE",
	ProxyEnvironment = "REACT_APP_PROXY_ENVIRONMENT",
	EnableTranslation = "REACT_APP_ENABLE_TRANSLATION"
}

declare global {
	interface Window {
		env?: Partial<Record<EnvVariable, string>>;
	}
}

function getCookieVar(
	key: string,
	defaultValue?: string,
	filter?: (value: string) => boolean
) {
	if (process.env.NODE_ENV !== "development") return defaultValue;

	const [value = ""] = document.cookie
		.split(";")
		.filter(item => item.trim().startsWith(`${key}=`))
		.map(c => c.split("=")[1]);

	const validValue = filter?.(value) ?? true;

	if (value && validValue) return value;

	if (defaultValue) setCookieVar(key, defaultValue);
	else if (!validValue) removeCookieVar(key);

	return defaultValue;
}

function setCookieVar(key: string, value: string) {
	document.cookie = `${key}=${value}; path=/`;
}

function removeCookieVar(key: string) {
	document.cookie = `${key}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
}

// Make sure we have _some_ kind of Storage compliant api when usef in e.g chrome's incognito mode.
// Avoid constructing value until it's actually needed, to allow eg. RequestCookieAccess to ask for access to localStorage if needed.
const relevantStorage = {
	_initialized: null as Storage | null,
	get storage(): Storage {
		if (this._initialized) return this._initialized;

		return (this._initialized = storageAvailable("localStorage")
			? window.localStorage
			: storageAvailable("sessionStorage")
			? window.sessionStorage
			: fallbackStorageFactory());
	}
};

function getLocalStorageVar(key: string, defaultValue?: string) {
	try {
		return window.localStorage.getItem(`ENV.${key}`) ?? defaultValue ?? "";
	} catch (e) {
		return defaultValue ?? "";
	}
}

function setLocalStorageVar(key: string, value: string) {
	try {
		window.localStorage.setItem(`ENV.${key}`, value);
	} catch (e) {}
}

function getEnvVar(key: EnvVariable): string | undefined;

function getEnvVar(key: EnvVariable, defaultValue: string): string;

function getEnvVar(
	key: EnvVariable,
	filter?: (value: string) => boolean
): string;

function getEnvVar(
	key: EnvVariable,
	defaultValue: string,
	filter?: (value: string) => boolean
): string;

function getEnvVar(
	key: EnvVariable,
	defaultValueOrFilter?: string | ((value: string) => boolean),
	filter?: (value: string) => boolean
) {
	let defaultValue: string | undefined = undefined;
	if (typeof defaultValueOrFilter === "string") {
		defaultValue = defaultValueOrFilter;
	} else if (defaultValueOrFilter && !filter) {
		filter = defaultValueOrFilter;
	}

	if (process.env.NODE_ENV === "development") {
		const cookieValue = getCookieVar(key, defaultValue, filter);

		if (cookieValue) return cookieValue;
	}

	let value = window.env?.[key];
	if (value && value !== `%${key}%`) return value;

	value = process.env[key];
	if (value && value !== `%${key}%`) return value;

	value = getLocalStorageVar(key, defaultValue);
	if (value) return value;

	return defaultValue;
}

function getEnv({ tenantFilter, envFilter }: Partial<EnvProviderProps> = {}) {
	if (process.env.NODE_ENV === "development") {
		return {
			tenant: getEnvVar(EnvVariable.Tenant, "Demo", tenantFilter),
			locale: getEnvVar(EnvVariable.Locale, window.navigator.language),
			design: getEnvVar(EnvVariable.Design),
			environment: getEnvVar(EnvVariable.ProxyEnvironment, envFilter),
			tenantFilter: tenantFilter ?? (() => true),
			envFilter: envFilter ?? (() => true)
		};
	}

	return {
		tenant: getEnvVar(EnvVariable.Tenant, "Demo", tenantFilter),
		locale: getEnvVar(EnvVariable.Locale, window.navigator.language),
		design: getEnvVar(EnvVariable.Design),
		environment: undefined,
		tenantFilter: () => true,
		envFilter: () => true
	};
}

export type EnvContext = ReturnType<typeof getEnv>;

export interface EnvAction {
	type: EnvVariable;
	payload: string;
}

const envContext = createContext(getEnv());
envContext.displayName = "Env";

const dispatchContext = createContext<React.Dispatch<EnvAction>>(() => {});
dispatchContext.displayName = "EnvDispatch";

export interface EnvProviderProps {
	children: React.ReactChild;
	tenantFilter?: (tenant: string) => boolean;
	envFilter?: (env: string) => boolean;
}

function CookieProvider(props: EnvProviderProps) {
	const [context, dispatch] = useReducer(
		(state: EnvContext, action: EnvAction): EnvContext => {
			if (action.type) setCookieVar(action.type, action.payload);

			return getEnv(props);
		},
		getEnv(props)
	);

	const unMounting = useRef(false);
	useEffect(
		() => () => {
			unMounting.current = true;
		},
		[]
	);

	const proxyEnvironemnt =
		!context.environment || context.environment.includes("local")
			? context.environment
			: context.tenant + context.environment;

	useEffect(
		() => () => {
			if (!unMounting.current) {
				window.location.reload();
			}
		},
		[proxyEnvironemnt]
	);

	return (
		<dispatchContext.Provider value={dispatch}>
			<envContext.Provider value={context} {...props} />
		</dispatchContext.Provider>
	);
}

function LocalStorageProvider(props: EnvProviderProps) {
	const [context, dispatch] = useReducer(
		(state: EnvContext, action: EnvAction): EnvContext => {
			if (action.type) setLocalStorageVar(action.type, action.payload);

			return getEnv(props);
		},
		getEnv(props)
	);

	return (
		<dispatchContext.Provider value={dispatch}>
			<envContext.Provider value={context} {...props} />
		</dispatchContext.Provider>
	);
}

export const EnvProvider =
	process.env.NODE_ENV === "development"
		? CookieProvider
		: LocalStorageProvider;

export function useSettings() {
	return useContext(envContext);
}

export function useSettingsDispatch() {
	return useContext(dispatchContext);
}

function storageAvailable(type: "localStorage" | "sessionStorage") {
	let storage: Storage | undefined;
	try {
		storage = window[type];
		const x = "__storage_test__";
		storage.setItem(x, x);
		storage.removeItem(x);
		return true;
	} catch (e) {
		return (
			e instanceof DOMException &&
			// everything except Firefox
			(e.code === 22 ||
				// Firefox
				e.code === 1014 ||
				// test name field too, because code might not be present
				// everything except Firefox
				e.name === "QuotaExceededError" ||
				// Firefox
				e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
			// acknowledge QuotaExceededError only if there's something already stored
			storage &&
			storage.length !== 0
		);
	}
}

function fallbackStorageFactory(): Storage {
	const storage = new Map<string, string>();

	return {
		getItem: key => storage.get(key) ?? null,
		setItem: (key, value: string | null | undefined) => {
			storage.set(
				key,
				value === null
					? "null"
					: value === undefined
					? "undefined"
					: value.toString()
			);
		},
		get length() {
			return storage.size;
		},
		clear: () => storage.clear(),
		key: index => (index < storage.size ? [...storage.keys()][index] : null),
		removeItem: key => {
			storage.delete(key);
		}
	};
}
