import { WithGetSet } from "./types";

export function mergeFunctions(
	from,
	to,
	variables: WithGetSet<number>,
	overrideMap
) {
	for (var section in overrideMap) {
		//		System.Console.WriteLine('Found section: ' + section);
		var sectionContent = overrideMap[section];
		for (var key in sectionContent) {
			//			System.Console.WriteLine('Found key: ' + key);
			if (key > from && key <= to) {
				//				System.Console.WriteLine('Passed key: ' + key);
				var variableMap = sectionContent[key];
				for (var variableName in variableMap) {
					//					System.Console.WriteLine('Hallo: ' + variableName);
					variables.set(variableName, variableMap[variableName]);
				}
			}
		}
	}

	/*
		In plain english (missing sections!):

		var items = Object.keys(overrideMap).filter(function (x) { return x < to && x >= from; });
		items.forEach(function (x) {
			Object.keys(overrideMap[x]).forEach(function (y) {
				variables.set(y, overrideMap[x][y]);
			});
		}); */

	return variables;
}

export function formatNumber(num, locale, format) {
	return num.toString().replace(/\./, ",");
}

export function round(number, digits = 0) {
	let multiplier = Math.pow(10, digits);
	return Math.round(number * multiplier) / multiplier;
}
