import { useMemo } from "react";

import { useResource, useRetrieve } from "@rest-hooks/core";
import { DployImage } from "@ploy-lib/types";
import {
	TenantLoader,
	loadTenantSettings
} from "../endpoints/loadTenantSettings";
import {
	loadIntlMessages,
	MessagesLoader
} from "../endpoints/loadIntlMessages";
import { useBrandSettings } from "./useBrandSettings";
import { useLanguageSettings, localeLang } from "./useLanguageSettings";

export function useTenantSettings(
	tenantName: string,
	loaders: {
		tenant: TenantLoader;
		messages: MessagesLoader;
	},
	currentLocale: string,
	defaultLocale?: string,
	themeImages: Record<string, DployImage[]> = {}
) {
	const tenant = useResource(loadTenantSettings, {
		tenantName,
		loader: loaders.tenant
	});

	const { locale, messages, formats } = useLanguageSettings(
		tenant,
		loaders.messages,
		currentLocale,
		defaultLocale
	);
	let language = localeLang(locale);
	// Initiate loading of default messages early.
	useRetrieve(loadIntlMessages, {
		language,
		loader: loaders.messages
	});

	const { brand } = useBrandSettings(themeImages);

	return useMemo(
		() => ({
			locale,
			tenant,
			messages,
			formats,
			brand
		}),
		[brand, formats, locale, messages, tenant]
	);
}
