import React from "react";
import { defineMessages, injectIntl } from "react-intl";
import marked from "@ploy-lib/purify-marked";

export const messages = defineMessages({
	privacyPolicy: {
		id: "direct.privacypolicy",
		defaultMessage: "No privacy policy",
		description: "Privacy Policy"
	}
});
export const PrivacyPolicy = injectIntl(({ intl }) => {
	const translated = intl.formatMessage(messages.privacyPolicy);
	return (
		<div
			style={{ textAlignLast: "center" }}
			className="additionalInfo"
			dangerouslySetInnerHTML={{
				__html: marked(translated)
			}}
		/>
	);
});
