import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

export const ErrorPage = ({ location, error, getLoginHref }) => {
	const status = error?.response?.status ?? error.statusCode;

	if (status === 401 && getLoginHref) {
		const returnUrl =
			location &&
			(new URL(getLoginHref(), location.origin).origin === location.origin
				? location.pathname
				: location.href);

		if (window) window.location.href = getLoginHref(returnUrl);
		return null;
	}

	let header = (
		<FormattedMessage
			id="dploy.error.default"
			defaultMessage="An error has occured"
		/>
	);

	let body = undefined;

	if (process.env.NODE_ENV === "development") {
		const isHtml = error.response?.headers
			.get("Content-Type")
			?.includes("html");

		// eslint-disable-next-line react-hooks/rules-of-hooks
		const [html, setHtml] = useState();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useEffect(() => {
			if (!isHtml) return;

			let active = true;
			(async () => {
				const text = await error.response.text();
				if (active) setHtml(text);
			})();
			return () => {
				active = false;
			};
		}, [error, isHtml]);

		body = (
			<>
				<pre component="pre" style={{ color: "red", paddingBottom: 32 }}>
					{error.stack}
				</pre>
				{html && (
					<iframe
						frameBorder={0}
						srcDoc={html}
						width="100%"
						height="800px"
						title="Network error response"
					/>
				)}
			</>
		);
	}

	if (status) {
		header = (
			<FormattedMessage
				id="dploy.error.status"
				defaultMessage="{status}: {status, select, 401 {No access} 403 {No access} 404 {Not found} other {An error has occurred}}"
				values={{ status }}
			/>
		);
	}

	return (
		<div style={{ padding: 32, maxWidth: 800, margin: "0 auto" }}>
			<h5 style={{ paddingBottom: 32 }}>{header}</h5>
			{body}
		</div>
	);
};
