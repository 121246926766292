import { mergeFunctions, formatNumber, round } from "./utils";
import { Financial } from "./financial";
import { FinancialUtils } from "./financialUtils";
import { calcRulesBase } from "./calcRulesBase";
import {
	SelectedField,
	AutomaticObjectPriceCalculation
} from "./selectedField";
import { testFunction } from "./testFunction";

export const CalcRulesLib = {
	mergeFunctions,
	formatNumber,
	round,
	Financial,
	FinancialUtils,
	calcRulesBase,
	testFunction,
	SelectedField,
	AutomaticObjectPriceCalculation
};
