const marked = require("marked");
const createDOMPurify = require("dompurify");

const DOMPurify = createDOMPurify(window);

// Add a hook to make all links open a new window
DOMPurify.addHook("afterSanitizeAttributes", function (node) {
	// set all elements owning target to target=_blank and rel=noopener noreferer https://web.dev/external-anchors-use-rel-noopener/
	// https://github.com/cure53/DOMPurify/blob/e1c19cf6407d782b666cb1d02a6af191f9cbc09e/demos/README.md#hook-to-open-all-links-in-a-new-window-link
	// Has impact on analytics: Direct Traffic vs Referral
	if ("target" in node) {
		node.setAttribute("target", "_blank");
		node.setAttribute("rel", "noopener noreferer");
	}
});

function purifyMarked(markdownString, options, callback) {
	return DOMPurify.sanitize(marked(markdownString, options, callback));
}

module.exports = Object.assign(purifyMarked, marked);
