import { useCallback, useState } from "react";
import { Cancel, Info } from "@material-ui/icons";
import { defineMessages, useIntl } from "react-intl";

function Checkbox(props) {
	const [infoVisible, setInfoVisible] = useState(false);
	const { showInfo } = props;

	const toggleInfo = useCallback(
		e => {
			e.preventDefault();
			setInfoVisible(!infoVisible);
		},
		[infoVisible]
	);
	const intl = useIntl();

	const info = intl.formatMessage(messages.CCIExplanation);

	const infoBox =
		showInfo && info.length > 0 ? (
			<div className="info-box-big info-box-bottom">
				<Cancel className="right" onClick={toggleInfo} />
				<div
					dangerouslySetInnerHTML={{
						__html: info
					}}
				></div>
			</div>
		) : null;

	return (
		<div className="checkbox-wrapper">
			<label>
				<input
					type="checkbox"
					className="s2 checkbox filled-in error"
					required
					name={props.name}
					checked={props.value}
					onChange={e => props.onChange(props.name, !props.value)}
				/>
				{props.label}
			</label>
			{showInfo ? <Info className="waves-effect" onClick={toggleInfo} /> : null}
			<span className="span-error">{props.errorText}</span>
			{infoVisible && infoBox}
		</div>
	);
}

export default Checkbox;

export const messages = defineMessages({
	CCIExplanation: {
		id: "direct.applicationform.checkbox.info",
		defaultMessage: "explanation for CCI checkbox",
		description: "explanation for CCI checkbox"
	}
});
