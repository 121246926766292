import React, { useState, useCallback } from "react";
import { Cancel } from "@material-ui/icons";
import { Info } from "@material-ui/icons";

const RadioGroup = props => {
	const { info } = props;
	const [infoVisible, setInfoVisible] = useState(false);
	const toggleInfo = useCallback(
		e => {
			e.preventDefault();
			setInfoVisible(!infoVisible);
		},
		[infoVisible]
	);

	const infoBox = info ? (
		<div className="info-box-big">
			<Cancel className="right" onClick={toggleInfo} />
			<div
				dangerouslySetInnerHTML={{
					__html: info
				}}
			></div>
		</div>
	) : null;

	const handleChange = e => props.onChange(props.name, e.target.value);

	const mapOptions = item => {
		const id = props.name + "-" + props.id + "-" + item.value;
		return (
			<div key={id} className={props.radioClassName}>
				<label>
					<input
						name={props.name + props.id}
						type="radio"
						id={id}
						value={item.value}
						className="with-gap"
						onChange={handleChange}
						checked={props.value === item.value}
					/>
					<span>{item.label}</span>
				</label>
			</div>
		);
	};
	return (
		<div className="radio-group-wrapper">
			<div className={props.className}>
				<p>{props.label}</p>
				{props.options.map(mapOptions)}
				{props.children}
				<span className="span-error s12 col">{props.errorText}</span>
				{info ? <Info className="waves-effect" onClick={toggleInfo} /> : null}
			</div>
			{infoVisible && infoBox}
		</div>
	);
};

export default RadioGroup;
