import { Endpoint } from "@rest-hooks/core";
import { Tenant } from "../types";

export type MessagesLoader = (
	lang: string
) => Promise<Record<string, string> | null | undefined>;

export const loadIntlMessages = new Endpoint(
	async function ({
		tenant,
		language,
		loader
	}: {
		language: string;
		tenant?: Tenant;
		loader: MessagesLoader;
	}) {
		const messages = tenant
			? await tenant.getMessages(language)
			: await loader(language);

		return messages ?? {};
	},
	{
		key({ language, tenant }) {
			return `LOAD messages ${language} ${tenant?.tenantName ?? "default"}`;
		},
		dataExpiryLength: Infinity
	}
);
