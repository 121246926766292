import React from "react";
import { FormattedMessage } from "react-intl";
import RadioGroup from "./RadioGroup";
import SelectField from "./SelectField";
import FormattedOptionPairs from "./FormattedOptionPairs";

class Citizenship extends React.Component {
	static displayName = "Citizenship";

	static defaultProps = {
		validation: {},
		data: {},
		fieldState: {}
	};

	handleChange = (field, value) =>
		this.props.onChange(this.props.name, field, value);

	render() {
		const { validation, fieldState } = this.props;
		const data = this.props.data || {};
		const citizenTypes = data.CitizenTypeList || [];

		const requiredSelectText = (
			<FormattedMessage
				id="direct.validation.requiredSelectText"
				description="Text for obligatorisk nedtrekksliste"
				defaultMessage="Must select a value"
			/>
		);

		let citizenShipClass = "";
		if (fieldState.citizenType === "2") {
			citizenShipClass = "m6";
		} else if (fieldState.citizenType === "3") {
			citizenShipClass = "m4";
		}

		return (
			<div>
				<div className="additionalInfo">
					<FormattedOptionPairs
						optionPairName="CitizenTypeList"
						optionPairs={citizenTypes}
					>
						{optionPairs => (
							<RadioGroup
								className="citizenship col s12 left-align row"
								radioClassName="col s12 m4"
								label={
									<FormattedMessage
										id="direct.citizenship.radiogroup.additionalinfo.label"
										description="Label for applicationinfo"
										defaultMessage="Citizenship"
									/>
								}
								name="citizenType"
								id={this.props.name}
								value={fieldState.citizenType}
								onChange={this.handleChange}
								options={optionPairs.map(x => ({
									label: x.Text,
									value: x.Value
								}))}
								errorText={
									validation.citizenType === false ? requiredSelectText : null
								}
							/>
						)}
					</FormattedOptionPairs>

					{fieldState.citizenType && fieldState.citizenType !== "1" ? (
						<div className="col s12 row no-padding">
							{/*
                                <NumberField
                                className="col s12 m6 citizenshipYear left-align"
                                name="yearsInCountry"
                                value={fieldState.yearsInCountry}
                                label="Antall år i Norge "
                                onChange={this.handleChange}
                                box
                                errorText={validation.yearsInCountry === false ? requiredInputText : null}
                            />
                            */}
							<SelectField
								className="input-field col s12 m6 no-padding"
								name="yearsInCountry"
								value={fieldState.yearsInCountry}
								label={
									<FormattedMessage
										id="direct.citizenship.selectedfield.yearsincountry.label"
										description="Label for yearsincountry"
										defaultMessage="Number of years in Finland"
									/>
								}
								options={data.YearsInCountryList}
								onChange={this.handleChange}
								errorText={
									validation.yearsInCountry === false
										? requiredSelectText
										: null
								}
							/>
							<SelectField
								className="input-field col s12 m6 no-padding"
								name="nativeCountry"
								value={fieldState.nativeCountry}
								label={
									<FormattedMessage
										id="direct.citizenship.selectedfield.nativecountry.label"
										description="Label for nativecountry"
										defaultMessage="Country of Origin"
									/>
								}
								options={data.CountryList}
								onChange={this.handleChange}
								errorText={
									validation.nativeCountry === false ? requiredSelectText : null
								}
							/>
						</div>
					) : null}

					<div className="col s12 row no-padding">
						{fieldState.citizenType && fieldState.citizenType === "3" ? (
							<SelectField
								className={`input-field col s12 no-padding ${citizenShipClass}`}
								name="citizenship1"
								value={fieldState.citizenship1}
								label={
									<FormattedMessage
										id="direct.citizenship.selectedfield.citizenship1.label"
										description="Label for citizenship1"
										defaultMessage="Citizenship 1"
									/>
								}
								options={data.CountryList}
								onChange={this.handleChange}
								errorText={
									validation.citizenship1 === false ? requiredSelectText : null
								}
							/>
						) : null}
						{fieldState.citizenType && fieldState.citizenType !== "1"
							? [
									<SelectField
										className={`input-field col s12 no-padding ${citizenShipClass}`}
										name="citizenship2"
										value={fieldState.citizenship2}
										label={
											<FormattedMessage
												id="direct.citizenship.selectedfield.citizenship2.label"
												description="Label for citizenship2"
												defaultMessage="Citizenship 2"
											/>
										}
										options={data.CountryList}
										onChange={this.handleChange}
										errorText={
											validation.citizenship2 === false
												? requiredSelectText
												: null
										}
									/>,
									<SelectField
										className={`input-field col s12 no-padding ${citizenShipClass}`}
										name="Citizenship3"
										value={fieldState.citizenship3}
										label={
											<FormattedMessage
												id="direct.citizenship.selectedfield.citizenship3.label"
												description="Label for citizenship3"
												defaultMessage="Citizenship 3"
											/>
										}
										options={data.CountryList}
										onChange={this.handleChange}
										errorText={
											validation.citizenship3 === false
												? requiredSelectText
												: null
										}
									/>
							  ]
							: null}
					</div>
					<div className="clearfix" />
				</div>
			</div>
		);
	}
}

export default Citizenship;
