import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Result from "./Result";
import ApplicationForm from "./ApplicationForm";
import { ReactComponent as MainLogo } from "../images/main_logo.svg";
import { useTenant } from "@ploy-ui/tenants";

const App = () => {
	const [previousAppStatus, setPreviousAppStatus] = useState();
	const [appStatus, setAppStatus] = useState();
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState({});

	useEffect(() => {
		let { key } = getSerchParams();

		if (!key) {
			const params = getPathParams();
			key = params[params.length - 1];
		}

		fetchModel(key);
	}, []);

	function getSerchParams(search = window.location.search) {
		if (search.length < 2) return {};
		return search
			.slice(1)
			.split("&")
			.reduce((obj, param) => {
				const [key, value] = param.split("=");
				obj[key] = value;
				return obj;
			}, {});
	}

	async function fetchModel(key) {
		setLoading(true);

		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/application/${key || ""}`
			);
			if (response.ok) {
				const data = await response.json();

				setData(data);
				setAppStatus(data.AppStatus);
			} else {
				if (response.headers.get("content-type") === "application/json") {
					// const error = await response.json();
					// setError(error);
				} else {
					// setError("An error occurred.");
				}
			}
		} catch (e) {
			// setError(e.message);
		} finally {
			setLoading(false);
		}
	}

	function getPathParams(pathname = window.location.pathname) {
		if (pathname.length < 2) return [];
		return pathname.slice(1).split("/");
	}

	const handleStatusChange = newAppStatus => {
		setPreviousAppStatus(appStatus);
		setAppStatus(newAppStatus);
	};

	const handleBack = () => {
		setAppStatus(previousAppStatus);
	};

	const pageWrapper = page => {
		return (
			<div
				className={`page-${
					appStatus === "Submitted" ? "index" : "submit-wrapper"
				}`}
			>
				<div className="center-align">
					<div>
						<div className="container">
							<div className="logo">
								<MainLogo />
							</div>
							{loading ? (
								<div className="center-align page-spinner">
									<div className="preloader-wrapper big active">
										<div className="spinner-layer">
											<div className="circle-clipper left">
												<div className="circle" />
											</div>
											<div className="gap-patch">
												<div className="circle" />
											</div>
											<div className="circle-clipper right">
												<div className="circle" />
											</div>
										</div>
									</div>
								</div>
							) : (
								page
							)}
						</div>
					</div>
				</div>
			</div>
		);
	};

	const { locale } = useTenant();

	if (appStatus === "Submitted")
		return pageWrapper(
			<>
				<Helmet htmlAttributes={{ lang: locale, translate: "no" }} />
				<ApplicationForm
					data={data}
					appkey={data.Key}
					status={appStatus}
					onStatusChange={handleStatusChange}
					onBack={!appStatus && previousAppStatus && handleBack}
				/>
			</>
		);

	return pageWrapper(
		<>
			<Helmet htmlAttributes={{ lang: locale, translate: "no" }} />
			<Result
				data={data}
				appkey={data.Key}
				status={appStatus}
				onStatusChange={handleStatusChange}
				onBack={!appStatus && previousAppStatus && handleBack}
			/>
		</>
	);
};

App.displayName = "App";

export default App;
