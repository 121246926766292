import React from "react";
import { FormattedMessage, injectIntl, defineMessages } from "react-intl";
import SelectField from "./SelectField";
import NumberField from "./NumberField";
import TextField from "./TextField";
import FormattedOptionPairs from "./FormattedOptionPairs";
import RadioGroup from "./RadioGroup";
import marked from "@ploy-lib/purify-marked";

export const messages = defineMessages({
	pepExplanation: {
		id: "direct.loandetails.radiogroup.PoliticallyExposedPerson.explanation",
		defaultMessage: "Explanation of PEP",
		description: "explanation of PEP"
	}
});
class AdditionalInfo extends React.Component {
	static displayName = "AdditionalInfo";

	static defaultProps = {
		validation: {},
		data: {},
		fieldState: {},
		customer: "Customer",
		showRequiresPermit: false,
		showPoliticallyExposedPerson: false,
		showDueDay: false
	};

	constructor(props) {
		super(props);
		const { customer, additionalInfoFields, additionalInfoFieldsCoSigner } =
			this.props;
		const additionalFields =
			customer === "Customer"
				? additionalInfoFields
				: additionalInfoFieldsCoSigner;
		this.statics = {
			showProfessionalStatus: additionalFields.includes("ProfessionalStatus"),
			showEmployer: additionalFields.includes("Employer"),
			showEmployedYears: additionalFields.includes("EmployedYears"),
			showEmployedStartTime: additionalFields.includes("EmployedStartTime"),
			showEmployedEndTime: additionalFields.includes("EmployedEndTime"),
			showResidentialStatus: additionalFields.includes("ResidentialStatus"),
			showMaritalStatus: additionalFields.includes("MaritalStatus"),
			showDebt: additionalFields.includes("Debt"),
			showNetMonthlySalary: additionalFields.includes("NetMonthlySalary"),
			showGrossYearlySalaryDropdown: additionalFields.includes(
				"GrossYearlySalaryDropdown"
			)
		};
	}

	handleChange = (field, value) =>
		this.props.onChange(this.props.name, field, value);

	render() {
		const { validation, fieldState, customer, intl } = this.props;
		const data = this.props.data || {};

		const {
			showProfessionalStatus,
			showEmployer,
			showEmployedYears,
			showEmployedStartTime,
			showEmployedEndTime,
			showResidentialStatus,
			showMaritalStatus,
			showDebt,
			showNetMonthlySalary,
			showGrossYearlySalaryDropdown
		} = this.statics;

		const requiredInputText = (
			<FormattedMessage
				id="direct.validation.requiredTextField"
				description="Text for obligatorisk felt"
				defaultMessage="Required field"
			/>
		);
		const requiredSelectText = (
			<FormattedMessage
				id="direct.validation.requiredSelectText"
				description="Text for obligatorisk nedtrekksliste"
				defaultMessage="Must select a value"
			/>
		);

		const isEmployed = [
			"PERMANENT_EMPLOYEE_PRIVATE",
			"PERMANENT_EMPLOYEE_GOVERNMENTAL"
		].includes(fieldState.ProfessionalStatus);

		const isTemporaryEmployed = ["TEMPORARY_EMPLOYEE"].includes(
			fieldState.ProfessionalStatus
		);

		const translatedPEPText = intl.formatMessage(messages.pepExplanation);

		return (
			<div>
				<div className="additionalInfo">
					<>
						{showProfessionalStatus ? (
							<FormattedOptionPairs
								optionPairName="ProfessionalStatus"
								optionPairs={data.ProfessionalStatus}
							>
								{optionPairs => (
									<SelectField
										className="input-field col s12 row"
										name="ProfessionalStatus"
										value={fieldState.ProfessionalStatus}
										label={
											<FormattedMessage
												id="direct.additionalinfo.selectedfield.professionalStatus.label"
												description="Label for professionalStatus field"
												defaultMessage="Working conditions"
											/>
										}
										options={optionPairs}
										onChange={this.handleChange}
										errorText={
											validation.ProfessionalStatus === false
												? requiredSelectText
												: null
										}
									/>
								)}
							</FormattedOptionPairs>
						) : null}
						{(showEmployer || showEmployedYears) && isEmployed ? (
							<div className="row">
								{showEmployer ? (
									<TextField
										className="col s12 m6 input-box-wrapper left-align"
										box
										name="Employer"
										value={fieldState.Employer}
										label={
											<FormattedMessage
												id="direct.additionalinfo.textfield.employer.label"
												description="Label for Employer"
												defaultMessage="Employer"
											/>
										}
										onChange={this.handleChange}
										errorText={
											validation.Employer === false ? requiredInputText : null
										}
									/>
								) : null}
								{showEmployedYears ? (
									<NumberField
										className="col s12 m6 input-box-wrapper left-align"
										box
										name="EmployedYears"
										value={fieldState.EmployedYears}
										label={
											<FormattedMessage
												id="direct.additionalinfo.textfield.employedYears.label"
												description="Label for EmployedYears"
												defaultMessage="Employed years"
											/>
										}
										onChange={(field, value) =>
											value && value.toString().length > 2
												? null
												: this.handleChange(field, value)
										}
										errorText={
											validation.EmployedYears === false
												? requiredInputText
												: null
										}
									/>
								) : null}
							</div>
						) : null}
						{(showEmployedStartTime || showEmployedEndTime) &&
						isTemporaryEmployed ? (
							<div>
								<div class="row">
									{showEmployer ? (
										<TextField
											className="col s12 m6 input-box-wrapper left-align"
											box
											name="Employer"
											value={fieldState.Employer}
											label={
												<FormattedMessage
													id="direct.additionalinfo.textfield.employer.label"
													description="Label for Employer"
													defaultMessage="Employer"
												/>
											}
											onChange={this.handleChange}
											errorText={
												validation.Employer === false ? requiredInputText : null
											}
										/>
									) : null}
								</div>
								<div class="row">
									{showEmployedStartTime ? (
										<TextField
											className="col s12 m6 input-box-wrapper left-align"
											box
											name="EmployedStartTime"
											value={fieldState.EmployedStartTime}
											label={
												<FormattedMessage
													id="direct.additionalinfo.textfield.EmployedStartTime.label"
													description="Label for EmployedStartTime"
													defaultMessage="Date of employment (mm/DD/YYYY)"
												/>
											}
											onChange={this.handleChange}
											errorText={
												validation.EmployedStartTime === false
													? requiredInputText
													: null
											}
										/>
									) : null}
									{showEmployedEndTime && isTemporaryEmployed ? (
										<TextField
											className="col s12 m6 input-box-wrapper left-align"
											box
											name="EmployedEndTime"
											value={fieldState.EmployedEndTime}
											label={
												<FormattedMessage
													id="direct.additionalinfo.textfield.EmployedEndTime.label"
													description="Label for EmployedEndTime"
													defaultMessage="End of employment (mm/DD/YYYY)"
												/>
											}
											onChange={this.handleChange}
											errorText={
												validation.EmployedEndTime === false
													? requiredInputText
													: null
											}
										/>
									) : null}
								</div>
							</div>
						) : null}
						{showResidentialStatus ? (
							<FormattedOptionPairs
								optionPairName="ResidentialStatus"
								optionPairs={data.ResidentialStatus}
							>
								{optionPairs => (
									<SelectField
										className="input-field col s12 row"
										name="ResidentialStatus"
										value={fieldState.ResidentialStatus}
										label={
											<FormattedMessage
												id="direct.additionalinfo.selectedfield.residentialStatus.label"
												description="Label for ResidentialStatus field"
												defaultMessage="Housing form"
											/>
										}
										options={optionPairs}
										onChange={this.handleChange}
										errorText={
											validation.ResidentialStatus === false
												? requiredSelectText
												: null
										}
									/>
								)}
							</FormattedOptionPairs>
						) : null}
						{showMaritalStatus ? (
							<FormattedOptionPairs
								optionPairName="MaritalStatus"
								optionPairs={data.MaritalStatus}
							>
								{optionPairs => (
									<SelectField
										className="input-field col s12 row"
										name="MaritalStatus"
										value={fieldState.MaritalStatus}
										label={
											<FormattedMessage
												id="direct.additionalinfo.selectedfield.maritalStatus.label"
												description="Label for maritalStatus field"
												defaultMessage="Marital status"
											/>
										}
										options={optionPairs}
										onChange={this.handleChange}
										errorText={
											validation.MaritalStatus === false
												? requiredSelectText
												: null
										}
									/>
								)}
							</FormattedOptionPairs>
						) : null}
					</>

					<div className="additional-info-number-input-wrapper">
						<div className="col s12 row no-padding">
							{showDebt ? (
								<NumberField
									className="col s12 m6 input-box-wrapper left-align"
									name="Debt"
									value={fieldState.Debt}
									label={
										customer === "Customer" ? (
											<FormattedMessage
												id={`direct.additionalinfo.numberfield.debt.customer.label`}
												description="Label for debt field"
												defaultMessage="Your debt"
											/>
										) : (
											<FormattedMessage
												id="direct.additionalinfo.numberfield.debt.cosigner.label"
												description="Label for debt field"
												defaultMessage="Co-applicants' debts"
											/>
										)
									}
									info={
										customer === "Customer" ? (
											<FormattedMessage
												id="direct.additionalinfo.numberfield.debt.customer.info"
												description="Info for debt field"
												defaultMessage="Enter your own debt and your share of household debt."
											/>
										) : (
											<FormattedMessage
												id="direct.additionalinfo.numberfield.debt.cosigner.info"
												description="Info for debt field"
												defaultMessage="Enter your co-applicants' debt and his/her share of household debt."
											/>
										)
									}
									format="currency"
									onChange={this.handleChange}
									box
									allowZero
									errorText={
										validation.Debt === false ? requiredInputText : null
									}
								/>
							) : null}
							{showNetMonthlySalary ? (
								<NumberField
									className="col s12 m6 input-box-wrapper left-align"
									name="NetMonthlySalary"
									value={fieldState.NetMonthlySalary}
									label={
										customer === "Customer" ? (
											<FormattedMessage
												id="direct.additionalinfo.numberfield.netMonthlySalary.customer.label"
												description="Label for netMonthlySalary field"
												defaultMessage="Monthly Net Income"
											/>
										) : (
											<FormattedMessage
												id="direct.additionalinfo.numberfield.netMonthlySalary.cosigner.label"
												description="Label for netMonthlySalary field"
												defaultMessage="Co-applicants' monthly net income"
											/>
										)
									}
									info={
										customer === "Customer" ? (
											<FormattedMessage
												id="direct.additionalinfo.numberfield.netMonthlySalary.customer.info"
												description="Info for netMonthlySalary field"
												defaultMessage="Enter your net income per month. What is paid after tax in euro. The income must be documentable."
											/>
										) : (
											<FormattedMessage
												id="direct.additionalinfo.numberfield.netMonthlySalary.cosigner.info"
												description="Info for netMonthlySalary field"
												defaultMessage="Enter your co-applicants' net income per month. What is paid after tax in euro. The income must be documentable."
											/>
										)
									}
									format="currency"
									onChange={this.handleChange}
									box
									errorText={
										validation.NetMonthlySalary === false
											? requiredInputText
											: null
									}
								/>
							) : null}
							{showGrossYearlySalaryDropdown ? (
								<FormattedOptionPairs
									optionPairName="GrossYearlySalary"
									optionPairs={data.GrossYearlySalary}
								>
									{optionPairs => (
										<SelectField
											className="input-field col s12 m6"
											name="GrossYearlySalary"
											value={fieldState.GrossYearlySalary}
											label={
												customer === "Customer" ? (
													<FormattedMessage
														id="direct.additionalinfo.selectedfield.grossYearlySalary.customer.label"
														description="Label for grossYearlySalary field"
														defaultMessage="Gross yearly salary"
													/>
												) : (
													<FormattedMessage
														id="direct.additionalinfo.selectedfield.grossYearlySalary.coSigner.label"
														description="Label for grossYearlySalary field"
														defaultMessage="Co-applicants' gross yearly salary"
													/>
												)
											}
											options={optionPairs}
											onChange={this.handleChange}
											errorText={
												validation.GrossYearlySalary === false
													? requiredSelectText
													: null
											}
										/>
									)}
								</FormattedOptionPairs>
							) : null}
						</div>
						{this.props.showRequiresPermit === true &&
						this.props.customer === "Customer" ? (
							<div className="row">
								<RadioGroup
									className="col s12 m6 input-box-wrapper left-align"
									radioClassName="col s8 m3 l3"
									name="RequiresPermit"
									id={this.props.name}
									flexDirection="row"
									value={
										fieldState.RequiresPermit == null
											? ""
											: fieldState.RequiresPermit.toString()
									}
									label={
										<FormattedMessage
											id="direct.loandetails.textfield.RequiresPermit.label"
											description="Label for requires permit"
											defaultMessage="Requires Permit"
										/>
									}
									errorText={
										validation.RequiresPermit === false ? (
											<FormattedMessage
												id="direct.loandetails.radiogroup.RequiresPermit.notchosen.label"
												description="Label for required permit option must be selected"
												defaultMessage="Whether permit is required must be answered"
											/>
										) : null
									}
									onChange={this.handleChange}
									options={[
										{
											label: (
												<FormattedMessage
													id="global.yes"
													description="Yes"
													defaultMessage="Yes"
												/>
											),
											value: true.toString()
										},
										{
											label: (
												<FormattedMessage
													id="global.no"
													description="No"
													defaultMessage="No"
												/>
											),
											value: false.toString()
										}
									]}
								/>
							</div>
						) : null}
						{this.props.showDueDay === true &&
						this.props.customer === "Customer" ? (
							<div className="row">
								<NumberField
									className="col s12 m6 input-box-wrapper left-align"
									name="DueDay"
									min={1}
									max={30}
									value={fieldState.DueDay}
									label={
										<FormattedMessage
											id="direct.loandetails.numberfield.dueday.label"
											description="Label for Forfallsdag"
											defaultMessage="Due day"
										/>
									}
									format="number"
									onChange={this.handleChange}
									box
								/>
							</div>
						) : null}
						{this.props.showPoliticallyExposedPerson === true ? (
							<>
								<div className="row">
									<RadioGroup
										className="col s12 m6 input-box-wrapper left-align"
										radioClassName="col s8 m3 l3"
										name="PoliticallyExposedPerson"
										id={this.props.name}
										value={
											fieldState.PoliticallyExposedPerson == null
												? ""
												: fieldState.PoliticallyExposedPerson.toString()
										}
										label={
											<FormattedMessage
												id="direct.loandetails.radiogroup.PoliticallyExposedPerson.label"
												description="Label for politically exposed person"
												defaultMessage="Politically exposed person"
											/>
										}
										errorText={
											validation.PoliticallyExposedPerson === false ? (
												<FormattedMessage
													id="direct.loandetails.radiogroup.PoliticallyExposedPersonNotChosen.label"
													description="Label for politically exposed person option must be selected"
													defaultMessage="Whether person is politically exposed person must be answered"
												/>
											) : null
										}
										onChange={this.handleChange}
										options={[
											{
												label: (
													<FormattedMessage
														id="global.yes"
														description="Yes"
														defaultMessage="Yes"
													/>
												),
												value: true.toString()
											},
											{
												label: (
													<FormattedMessage
														id="global.no"
														description="No"
														defaultMessage="No"
													/>
												),
												value: false.toString()
											}
										]}
										info={marked(translatedPEPText)}
									/>
								</div>
							</>
						) : null}
					</div>
					<div className="clearfix" />
				</div>
			</div>
		);
	}
}

export default injectIntl(AdditionalInfo);
