import React, { useMemo } from "react";
import { IntlProvider } from "react-intl";
import Context from "../context";
import { getDefaultFormats } from "../formats";
import { useTenantSettings } from "../hooks/useTenantSettings";
import { useSettings } from "@ploy-lib/core";
import { DployImage } from "@ploy-lib/types";

export interface TenantIntlProviderProps {
	tenant?: string;
	defaultLocale?: string;
	locale?: string;
	defaultCurrency?: string;
	children: React.ReactNode;
	images?: Record<string, DployImage[]>;
	dashboardImageCropMode?: string;
}

const loaders = {
	tenant: (key: string) =>
		import(
			/* webpackInclude: /intl\.js$/, webpackChunkName: "tenants/[request]/intl" */
			`../tenants/${key}/intl`
		),
	messages: async (lang: string) =>
		(
			await import(
				/* webpackInclude: /\.json$/, webpackChunkName: "lang/[request]" */
				`../translations/${lang.toLowerCase()}`
			)
		)?.default
};

export function TenantIntlProvider(props: TenantIntlProviderProps) {
	const settings = useSettings();

	const {
		tenant: tenantName = settings.tenant || "Demo",
		locale: currentLocale = settings.locale,
		defaultCurrency = "NOK",
		defaultLocale = "nb-NO",
		children,
		images
	} = props;

	const defaultFormats = useMemo(
		() => getDefaultFormats(defaultCurrency),
		[defaultCurrency]
	);

	const { brand, formats, locale, messages, tenant } = useTenantSettings(
		tenantName,
		loaders,
		currentLocale,
		defaultLocale,
		images
	);

	const contextValue = useMemo(
		() => ({
			brand,
			formats,
			locale,
			messages,
			tenant
		}),
		[brand, formats, locale, messages, tenant]
	);

	return (
		<Context.Provider value={contextValue}>
			<IntlProvider
				defaultLocale={defaultLocale}
				defaultFormats={defaultFormats}
				messages={messages}
				locale={locale}
				formats={formats}
				onError={error => {
					// Default onError behaviour is log error if NODE_ENV !== "production"
					if (
						process.env.NODE_ENV !== "production" &&
						error.code !== "MISSING_TRANSLATION"
					) {
						console.error(error);
					}
				}}
			>
				{children}
			</IntlProvider>
		</Context.Provider>
	);
}
