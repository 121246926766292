import React from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import IconHeader from "./IconHeader";
import { ReactComponent as Car } from "../images/car.svg";

class ObjectInfo extends React.Component {
	static displayName = "ObjectInfo";

	render() {
		const object = this.props.object || {};

		return (
			<div>
				<div className="section section-header-wrapper">
					<IconHeader
						text={
							<FormattedMessage
								id="direct.objectinfo.iconheader.omgjenstanden.text"
								description="Text for iconheader"
								defaultMessage="Object"
							/>
						}
						icon={<Car />}
					/>
				</div>
				<div className="divider"></div>
				<div className="objectInfo">
					<table>
						<tbody>
							<tr>
								<th>
									<FormattedMessage
										id="direct.objectinfo.table.th.model"
										description="Text table th"
										defaultMessage="Model"
									/>
								</th>
								<td>
									{object.Make}
									<br />
									{object.Model}
									<br />
									{object.Engine}
									<br />
									{object.Year}
								</td>
							</tr>
							{object.Mileage ? (
								<tr>
									<th>
										<FormattedMessage
											id="direct.objectinfo.table.th.km"
											description="Text table th"
											defaultMessage="Meter"
										/>
									</th>
									<td>
										<FormattedMessage
											id="direct.objectinfo.table.th.mileage"
											description="Text table th"
											defaultMessage="{mileage, number} km"
											value={{ mileage: object.Mileage }}
										/>
									</td>
								</tr>
							) : null}
							{object.RegistrationNumber ? (
								<tr>
									<th>
										<FormattedMessage
											id="direct.objectinfo.table.th.regnumber"
											description="Text table th"
											defaultMessage="Reg.number"
										/>
									</th>
									<td>{object.RegistrationNumber}</td>
								</tr>
							) : null}
							<tr>
								<th>
									<FormattedMessage
										id="direct.objectinfo.table.th.pris"
										description="Text table th"
										defaultMessage="Price"
									/>
								</th>
								<td>
									<FormattedNumber value={object.Price} format="currency" />
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}

export default ObjectInfo;
