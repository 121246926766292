import { useMemo } from "react";
import { DployImage } from "@ploy-lib/types";

export function useBrandSettings(images: Record<string, DployImage[]> = {}) {
	const brand = useMemo(() => {
		const imageUrlsMap: Record<string, string[]> = {};

		for (const [type, imageList] of Object.entries(images)) {
			imageUrlsMap[type] = imageList.map(i => i.fileUrl);
		}

		return { images: imageUrlsMap };
	}, [images]);

	return {
		brand
	};
}
