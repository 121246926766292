import { CustomFormats } from "react-intl";

export const getDefaultFormats = (
	currency = "NOK",
	currencyDecimals = 0,
	currencyInputDecimals = currencyDecimals,
	percentageInputDecimals = 2,
	percentageMaxDecimals = percentageInputDecimals
): CustomFormats => ({
	number: {
		currency: {
			style: "currency",
			currency,
			maximumFractionDigits: currencyDecimals,
			minimumFractionDigits: currencyDecimals
		},
		currency_input: {
			style: "currency",
			currency,
			maximumFractionDigits: currencyInputDecimals,
			minimumFractionDigits: currencyInputDecimals
		},
		currency_full: {
			style: "currency",
			currency
		},
		percent: {
			style: "percent",
			maximumFractionDigits: percentageMaxDecimals
		},
		percent_input: {
			style: "percent",
			maximumFractionDigits: percentageInputDecimals,
			minimumFractionDigits: percentageInputDecimals
		},
		default: {
			maximumFractionDigits: currencyDecimals
		}
	},
	date: {
		long_datetime: {
			year: "numeric",
			month: "long",
			day: "numeric",
			hour: "numeric",
			minute: "numeric"
		},
		long: {
			year: "numeric",
			month: "long",
			day: "numeric"
		},
		short: {
			year: "numeric",
			month: "short",
			day: "numeric"
		},
		narrow: {
			year: "numeric",
			month: "narrow",
			day: "numeric"
		},
		"2-digit": {
			year: "2-digit",
			month: "2-digit",
			day: "2-digit"
		},
		numeric: {
			year: "numeric",
			month: "numeric",
			day: "numeric"
		},
		short_numeric: {
			year: "numeric",
			month: "2-digit",
			day: "2-digit"
		},
		short_withTime: {
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
			hour: "2-digit",
			minute: "2-digit",
			second: "2-digit"
		}
	},
	time: {
		"2-digit": {
			minute: "2-digit",
			second: "2-digit"
		},
		numeric: {
			minute: "numeric",
			second: "numeric"
		}
	},
	relative: {}
});
