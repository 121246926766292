import React from "react";
import { FormattedMessage } from "react-intl";
import IconHeader from "./IconHeader";
import PersonInfo from "./PersonInfo";
import ObjectInfo from "./ObjectInfo";
import LoanDetails from "./LoanDetails";
import Citizenship from "./Citizenship";
import AdditionalInfo from "./AdditionalInfo";
import AdditionalCompanyInfo from "./AdditionalCompanyInfo";
import EquityInfo from "./EquityInfo";
import AddCoSigner from "./AddCoSigner";
import Checkbox from "./Checkbox";
import { ReactComponent as Questionmark } from "../images/questionmark.svg";
import { ReactComponent as ArrowForward } from "../images/arrow_forward.svg";

import FinnishSSN from "finnish-ssn";
import { validateNorwegianIdNumber } from "norwegian-national-id-validator";
import { PrivacyPolicy } from "./PrivacyPolicy";

const debounce = (func, wait) => {
	let timeout;
	return function (...args) {
		clearTimeout(timeout);
		timeout = setTimeout(() => func.apply(this, args), wait);
	};
};

const isNullOrEmpty = x => typeof x === "undefined" || x == null || x === "";

class ApplicationForm extends React.Component {
	static displayName = "ApplicationForm";

	state = {
		application: {
			additionalInfoCustomer: {
				ProfessionalStatus: "",
				Employer: "",
				EmployedYears: "",
				EmployedStartTime: "",
				EmployedEndTime: "",
				ResidentialStatus: "",
				MaritalStatus: "",
				Debt: "",
				NetMonthlySalary: "",
				GrossYearlySalary: "",
				RequiresPermit: null,
				PoliticallyExposedPerson: null,
				PoliticallyExposedPersonSource: "",
				DueDay: null
			},
			additionalInfoCoSigner: {
				ProfessionalStatus: "",
				Employer: "",
				EmployedYears: "",
				EmployedStartTime: "",
				EmployedEndTime: "",
				ResidentialStatus: "",
				MaritalStatus: "",
				Debt: "",
				NetMonthlySalary: "",
				GrossYearlySalary: "",
				PoliticallyExposedPerson: null
			},
			nationalityInfoCustomer: {
				citizenType: null,
				citizenship1: null,
				//citizenship2: null,
				//citizenship3: null,
				nativeCountry: null,
				yearsInCountry: null
			},
			nationalityInfoCoSigner: {
				citizenType: null,
				citizenship1: null,
				//citizenship2: null,
				//citizenship3: null,
				nativeCountry: null,
				yearsInCountry: null
			},
			coSigner: {
				customerNumber: null,
				email: null,
				phoneMobile: null
			},
			equityInfo: {
				innbytte: null,
				innbytteRegNo: "",
				oppsparte: null,
				egetSalg: null,
				egetSalgRegNo: "",
				annet: null,
				annetForklaring: ""
			},
			loanDetails: {
				equity: this.props.data.LoanDetails
					? this.props.data.LoanDetails.Equity
					: "",
				terms: this.props.data.LoanDetails
					? this.props.data.LoanDetails.PaymentTerms
					: "",
				campaign: this.props.data.LoanDetails
					? this.props.data.LoanDetails.Campaign
					: "",
				VendorBuyBack: !this.props.data.CanShowVendorBuyBack ? null : false,
				paymentInsurance: this.props.data.LoanDetails.PaymentInsurance
			}
		},
		validation: {},
		calcResult: this.props.data.LoanDetails,
		calcError: false,
		calculating: false,
		submitting: false,
		terms: false,
		hasCoSigner:
			this.props.data.CustomerStatus &&
			this.props.data.CustomerStatus.HasCoSigner,
		coSignerNotFound: false,
		calcErrorMessage: ""
	};

	statics = {
		additionalInfoFields: this.props.data.AdditionalInfoFields || [],
		additionalInfoFieldsCoSigner:
			this.props.data.AdditionalInfoFieldsCoSigner || [],
		canToggleCoSigner:
			this.props.data.CanAddCoSigner &&
			!this.props.data.CustomerStatus.HasCoSigner,
		country: this.props.data.Country,
		regExCellPhone: this.props.data.RegExCellPhone,
		regExEmail: this.props.data.RegExEmail
	};

	validateEquityExplanations = field => {
		const equityInfo = this.state.application["equityInfo"];

		if (
			field === "annetForklaring" &&
			equityInfo["annet"] > 0 &&
			isNullOrEmpty(equityInfo[field])
		) {
			return false;
		}

		if (
			field === "innbytteRegNo" &&
			equityInfo["innbytte"] > 0 &&
			isNullOrEmpty(equityInfo[field])
		) {
			return false;
		}
		if (
			field === "egetSalgRegNo" &&
			equityInfo["egetSalg"] > 0 &&
			isNullOrEmpty(equityInfo[field])
		) {
			return false;
		}

		return true;
	};

	validateRequired = (sectionName, field) => {
		// Equity info and debt fields are not currently required
		if (sectionName === "equityInfo")
			return this.validateEquityExplanations(field);
		if (field === "campaign") return true;
		if (field === "VendorBuyBack") return true;
		if (field === "paymentInsurance") return true;

		return !isNullOrEmpty(this.state.application[sectionName][field]);
	};

	validateTerms = () => this.state.terms === true;

	// Sum of equity info fields must equal the equity
	validateEquitySum = () => {
		var equitySum = Object.keys(this.state.application.equityInfo)
			.map(k => this.state.application.equityInfo[k])
			.filter(x => typeof x === "number")
			.reduce((a, b) => a + b, 0);
		return (
			(equitySum === 0 &&
				this.state.application.loanDetails.equity <
					this.props.data.EquityLimit) ||
			equitySum === this.state.application.loanDetails.equity
		);
	};

	isValid = isPrivateCustomer => {
		const { validation, hasCoSigner } = this.state;
		const {
			additionalInfoFields,
			additionalInfoFieldsCoSigner,
			canToggleCoSigner
		} = this.statics;

		if (!isPrivateCustomer)
			return Object.keys(validation).every(sectionName => {
				const section = validation[sectionName];
				if (sectionName === "equityInfo")
					return Object.keys(section).every(field => {
						return section[field] !== false;
					});
				if (sectionName === "additionalInfoCustomer")
					return Object.keys(section).every(field => {
						if (
							(this.props.data.CanShowRequiresPermit &&
								field === "RequiresPermit") ||
							(true &&
								(field === "PoliticallyExposedPerson" ||
									field === "PoliticallyExposedPersonSource"))
						)
							return section[field];
						return true;
					});
				if (sectionName === "terms") return section !== false;
				return true;
			});

		return Object.keys(validation).every(sectionName => {
			const section = validation[sectionName];
			if (
				!hasCoSigner &&
				(sectionName === "additionalInfoCoSigner" ||
					sectionName === "nationalityInfoCoSigner")
			)
				return true;
			if (sectionName === "coSigner" && (!hasCoSigner || !canToggleCoSigner))
				return true;
			if (sectionName === "additionalInfoCustomer")
				return Object.keys(section).every(field => {
					if (
						(this.props.data.CanShowRequiresPermit &&
							field === "RequiresPermit") ||
						(this.props.data.CanShowPoliticallyExposedPerson &&
							field === "PoliticallyExposedPerson") ||
						([
							"PERMANENT_EMPLOYEE_PRIVATE",
							"PERMANENT_EMPLOYEE_GOVERNMENTAL"
						].includes(
							this.state.application[sectionName].ProfessionalStatus
						) &&
							(field === "Employer" || field === "EmployedYears")) ||
						(this.state.application[sectionName].ProfessionalStatus ===
							"TEMPORARY_EMPLOYEE" &&
							(field === "Employer" ||
								field === "EmployedStartTime" ||
								field === "EmployedEndTime"))
					)
						return section[field];
					return true;
				});
			if (sectionName === "additionalInfoCoSigner")
				return Object.keys(section).every(field => {
					if (
						(this.props.data.CanShowPoliticallyExposedPerson &&
							field === "PoliticallyExposedPerson") ||
						([
							"PERMANENT_EMPLOYEE_PRIVATE",
							"PERMANENT_EMPLOYEE_GOVERNMENTAL"
						].includes(
							this.state.application[sectionName].ProfessionalStatus
						) &&
							(field === "Employer" || field === "EmployedYears")) ||
						(this.state.application[sectionName].ProfessionalStatus ===
							"TEMPORARY_EMPLOYEE" &&
							(field === "Employer" ||
								field === "EmployedStartTime" ||
								field === "EmployedEndTime"))
					)
						return section[field];
					return true;
				});
			if (
				sectionName === "nationalityInfoCustomer" &&
				!additionalInfoFields.includes("NationalityInfo")
			)
				return true;
			if (
				sectionName === "nationalityInfoCoSigner" &&
				!additionalInfoFieldsCoSigner.includes("NationalityInfo")
			)
				return true;
			if (typeof section === "object") {
				return Object.keys(section).every(field => {
					if (
						sectionName === "additionalInfoCustomer" &&
						!additionalInfoFields.includes(field)
					)
						return true;
					if (
						sectionName === "additionalInfoCoSigner" &&
						!additionalInfoFieldsCoSigner.includes(field)
					)
						return true;
					if (
						(field === "Employer" || field === "EmployedYears") &&
						![
							"PERMANENT_EMPLOYEE_PRIVATE",
							"PERMANENT_EMPLOYEE_GOVERNMENTAL"
						].includes(this.state.application[sectionName].ProfessionalStatus)
					)
						return true;
					if (
						(field === "nativeCountry" || field === "yearsInCountry") &&
						this.state.application[sectionName].citizenType === "1"
					)
						return true;
					if (
						field === "citizenship1" &&
						this.state.application[sectionName].citizenType !== "3"
					)
						return true;
					return section[field] !== false;
				});
			} else {
				return section !== false;
			}
		});
	};

	validateField = (sectionName, field, value) => {
		const { country, regExCellPhone, regExEmail } = this.statics;
		const { validation } = this.state;
		const extraFields = {};
		let extraObject = {};

		if (sectionName === "loanDetails" && field === "equity") {
			extraObject = {
				equityInfo: {
					...validation.equityInfo,
					equitySum: this.validateEquitySum()
				}
			};
		}

		if (sectionName === "equityInfo") {
			extraFields.equitySum = this.validateEquitySum();
		}

		if (field === "citizenType") {
			extraFields.yearsInCountry = this.validateRequired(
				sectionName,
				"yearsInCountry"
			);
			extraFields.nativeCountry = this.validateRequired(
				sectionName,
				"nativeCountry"
			);
			extraFields.citizenship1 = this.validateRequired(
				sectionName,
				"citizenship1"
			);
		}

		if (sectionName === "coSigner") {
			if (field === "customerNumber") {
				switch (country) {
					case "Finland":
						extraFields.customerNumber = FinnishSSN.validate(value);
						break;
					default:
						extraFields.customerNumber = validateNorwegianIdNumber(value);
				}
			}

			if (field === "phoneMobile")
				extraFields.phoneMobile = value.match(regExCellPhone) != null;
			if (field === "email")
				extraFields.email = value.match(regExEmail) != null;
		}

		this.setState({
			validation: {
				...this.state.validation,
				...extraObject,

				[sectionName]: {
					...this.state.validation[sectionName],
					[field]: this.validateRequired(sectionName, field),
					...extraFields
				}
			}
		});

		return;
	};

	validateSubSection = name => {
		const { application } = this.state;
		return Object.keys(application[name]).reduce(
			(obj, field) => ({ ...obj, [field]: this.validateRequired(name, field) }),
			{}
		);
	};

	componentDidMount = () => {
		this.validateAllFields();
	};

	componentDidUpdate = (_, prevState) => {
		if (prevState.hasCoSigner !== this.state.hasCoSigner)
			this.validateAllFields();
	};

	validateAllFields = () => {
		const equityInfo = this.validateSubSection("equityInfo");
		if (equityInfo) {
			equityInfo.equitySum = this.validateEquitySum();
		}

		this.setState({
			validation: {
				additionalInfoCustomer: this.validateSubSection(
					"additionalInfoCustomer"
				),
				additionalInfoCoSigner: this.validateSubSection(
					"additionalInfoCoSigner"
				),
				nationalityInfoCustomer: this.validateSubSection(
					"nationalityInfoCustomer"
				),
				nationalityInfoCoSigner: this.validateSubSection(
					"nationalityInfoCoSigner"
				),
				loanDetails: this.validateSubSection("loanDetails"),
				equityInfo,
				terms: this.validateTerms(),
				coSigner: this.validateSubSection("coSigner")
			}
		});
	};

	toggleCoSigner = () => {
		this.setState(({ hasCoSigner }) => ({
			hasCoSigner: !hasCoSigner
		}));
	};

	handleChange = (name, field, value) => {
		console.log(name, field, value);

		this.setState(
			({ application }) => ({
				application: {
					...application,
					[name]: {
						...application[name],
						[field]: value
					}
				}
			}),
			() => {
				// Validate fields after changes
				this.validateField(name, field, value);

				// Calculate if loan detail fields have changed
				if (name === "loanDetails") this.calculate(this.state.application);
			}
		);
	};

	handleTermsChange = (field, value) => {
		this.setState({ terms: value }, () => {
			this.setState({
				validation: { ...this.state.validation, terms: this.validateTerms() }
			});
		});
	};

	handleSubmit = isPrivateCustomer => {
		// Validate all fields before submitting
		const { additionalInfoCustomer, equityInfo, loanDetails } =
			this.state.application;
		const additionalInfoCustomerValid = Object.keys(
			additionalInfoCustomer
		).reduce(
			(obj, field) => ({
				...obj,
				[field]: this.validateRequired("additionalInfoCustomer", field)
			}),
			{}
		);
		const equityInfoValid = Object.keys(equityInfo).reduce(
			(obj, field) => ({
				...obj,
				[field]: this.validateRequired("equityInfo", field)
			}),
			{}
		);
		const loanDetailsValid = Object.keys(loanDetails).reduce(
			(obj, field) => ({
				...obj,
				[field]: this.validateRequired("loanDetails", field)
			}),
			{}
		);

		this.setState(
			{
				validation: {
					additionalInfoCustomer: additionalInfoCustomerValid,
					equityInfo: equityInfoValid,
					loanDetails: loanDetailsValid,
					terms: this.validateTerms()
				}
			},
			() =>
				this.isValid(isPrivateCustomer) && this.submit(this.state.application)
		);
	};

	submit = async application => {
		const { appkey, status } = this.props;

		this.setState({ submitting: true });

		const base = process.env.REACT_APP_API_URL;

		try {
			const response = await fetch(`${base}/submit/${appkey}/submit`, {
				method: "POST",
				headers: new Headers({
					"Content-type": "application/json"
				}),
				body: JSON.stringify(application)
			});
			if (response.ok) {
				const data = await response.json();
				if (data.AppStatus !== status)
					this.props.onStatusChange(data.AppStatus);
			} else if (response.status === 404)
				this.setState({ coSignerNotFound: true });
			else {
				this.props.onStatusChange();
			}
		} catch (e) {
			//todo: show error on screen
			console.log("Error ", e);
			this.props.onStatusChange();
		} finally {
			this.setState({ submitting: false });
		}
	};

	calculate = debounce(async application => {
		const { appkey } = this.props;

		this.setState({
			calculating: true,
			calcError: false,
			calcErrorMessage: ""
		});

		const base = process.env.REACT_APP_API_URL;

		try {
			const response = await fetch(`${base}/Calculation/calculate/${appkey}`, {
				method: "POST",
				headers: new Headers({
					"Content-type": "application/json"
				}),
				body: JSON.stringify(application.loanDetails)
			});

			if (response.ok) {
				const data = await response.json();

				this.setState(({ calcResult, application }) => ({
					calcResult: {
						...calcResult,
						...data
					},
					application: {
						...application,
						loanDetails: {
							...application.loanDetails,
							paymentInsurance: data.PaymentInsurance
						}
					}
				}));
			} else {
				const data = await response.json();
				this.setState({ calcError: true, calcErrorMessage: data.Message });
			}
		} catch (e) {
			//todo: show error on screen
			console.log("Error ", e);
			this.setState({ calcError: true });
		} finally {
			this.setState({ calculating: false });
		}
	}, 200);

	render() {
		const { data } = this.props;
		const isPrivateCustomer = data.Customer.CustomerType === "Private";
		const isLeasing = data.LoanDetails.ProductType === 2;
		const showPrivacyPolicy = data.ShowPrivacyPolicy;

		const {
			application,
			validation,
			calcResult,
			calcError,
			calculating,
			submitting,
			terms,
			hasCoSigner,
			coSignerNotFound,
			calcErrorMessage
		} = this.state;
		const {
			canToggleCoSigner,
			additionalInfoFields,
			additionalInfoFieldsCoSigner
		} = this.statics;

		const shouldSpecifyEquityValues =
			(data.EquityLimit > 0 &&
				application.loanDetails.equity >= data.EquityLimit) ||
			(data.EquityLimit === 0 && application.loanDetails.equity > 0);

		return (
			<div className="page-index">
				<div className="section page-index-header">
					<div className="col s12">
						<h3>
							<FormattedMessage
								id="direct.applicationform.h3"
								description="Header for applicationform"
								defaultMessage="Finish your application"
							/>
						</h3>
					</div>
				</div>

				<div>
					<PersonInfo customer={data.Customer} />
					<ObjectInfo object={data.AppObject} />
					<LoanDetails
						data={calcResult}
						errorMessage={
							(calcError && calcErrorMessage === "" && (
								<FormattedMessage
									id="direct.applicationform.calculate.error"
									description="Text for error msg"
									defaultMessage="Error in calculation, the values ​​below will not be correct. Please try again."
								/>
							)) ||
							calcErrorMessage
						}
						onChange={this.handleChange}
						onCalculate={this.handleCalculate}
						fieldState={application.loanDetails}
						name={"loanDetails"}
						maxRepaymentTermsLimit={data.MaxRepaymentTermsLimit}
						calculating={calculating}
						validation={validation.loanDetails || {}}
						showCampaign={this.props.data.CanSpecifyCampaign}
						availableCampaigns={this.props.data.Campaigns}
						showVendorBuyBack={
							this.props.data.CanShowVendorBuyBack === null
								? false
								: this.props.data.CanShowVendorBuyBack
						}
					/>

					{/* Main customer */}
					{isPrivateCustomer ? (
						<div className="section section-header-wrapper">
							<IconHeader
								text={
									<FormattedMessage
										id="direct.applicationform.iconheader.opplysninger.text"
										description="Iconheader text"
										defaultMessage="Information"
									/>
								}
								icon={<Questionmark />}
							/>
						</div>
					) : null}

					<div className="divider" />
					{additionalInfoFields.includes("NationalityInfo") &&
					isPrivateCustomer ? (
						<Citizenship
							data={data.CustomerStatus}
							onChange={this.handleChange}
							fieldState={application.nationalityInfoCustomer}
							name={"nationalityInfoCustomer"}
							validation={validation.nationalityInfoCustomer || {}}
						/>
					) : null}

					{isPrivateCustomer ? (
						<AdditionalInfo
							data={data.CustomerStatus}
							onChange={this.handleChange}
							fieldState={application.additionalInfoCustomer}
							name={"additionalInfoCustomer"}
							showDueDay={this.props.data.CanSpecifyDueDay}
							showRequiresPermit={this.props.data.CanShowRequiresPermit}
							showPoliticallyExposedPerson={
								this.props.data.CanShowPoliticallyExposedPerson
							}
							validation={validation.additionalInfoCustomer || {}}
							{...this.statics}
						/>
					) : (
						<AdditionalCompanyInfo
							data={data.CustomerStatus}
							onChange={this.handleChange}
							fieldState={application.additionalInfoCustomer}
							name={"additionalInfoCustomer"}
							showRequiresPermit={this.props.data.CanShowRequiresPermit}
							showPoliticallyExposedPerson={
								this.props.data.CanShowPoliticallyExposedPerson
							}
							validation={validation.additionalInfoCustomer || {}}
							{...this.statics}
						/>
					)}

					{/* CoSigner */}
					{(hasCoSigner || canToggleCoSigner) && isPrivateCustomer ? (
						<div className="section section-header-wrapper">
							<IconHeader
								text={
									<FormattedMessage
										id="direct.applicationform.iconheader.opplysningermedsoker.text"
										description="Iconheader text"
										defaultMessage="Information co-applicant"
									/>
								}
								iconUrl={<Questionmark />}
							/>
						</div>
					) : null}
					{canToggleCoSigner && isPrivateCustomer ? (
						<AddCoSigner
							name={"coSigner"}
							toggleCoSigner={this.toggleCoSigner}
							hasCosigner={hasCoSigner}
							handleChange={this.handleChange}
							coSignerData={application.coSigner}
							validation={validation.coSigner || {}}
						/>
					) : null}
					{hasCoSigner && isPrivateCustomer ? (
						<>
							{additionalInfoFieldsCoSigner.includes("NationalityInfo") ? (
								<Citizenship
									data={data.CustomerStatus}
									onChange={this.handleChange}
									fieldState={application.nationalityInfoCoSigner}
									name={"nationalityInfoCoSigner"}
									validation={validation.nationalityInfoCoSigner || {}}
									customer={"CoSigner"}
								/>
							) : null}
							<AdditionalInfo
								data={data.CustomerStatus}
								onChange={this.handleChange}
								fieldState={application.additionalInfoCoSigner}
								name={"additionalInfoCoSigner"}
								showRequiresPermit={this.props.data.CanShowRequiresPermit}
								showPoliticallyExposedPerson={
									this.props.data.CanShowPoliticallyExposedPerson
								}
								validation={validation.additionalInfoCoSigner || {}}
								onlyDebtCapacity={true}
								customer={"CoSigner"}
								{...this.statics}
							/>
							<div className="divider" />
						</>
					) : null}
					{shouldSpecifyEquityValues ? (
						<EquityInfo
							onChange={this.handleChange}
							currentEquity={application.loanDetails.equity}
							isPrivateCustomer={isPrivateCustomer}
							isLeasing={isLeasing}
							fieldState={application.equityInfo}
							name={"equityInfo"}
							validation={validation.equityInfo || {}}
						/>
					) : null}
					{showPrivacyPolicy && <PrivacyPolicy />}
				</div>

				<div className="section page-index-submit-wrapper">
					<div className="submit-content">
						<Checkbox
							label={
								<FormattedMessage
									tagName="span"
									id="direct.applicationform.checkbox.label"
									description="Label for applicationform submitbtn"
									defaultMessage="I agree that DNB collects necessary information and makes a credit rating of me. I agree that the outcome of the credit processing is shared with seller."
								/>
							}
							name="terms"
							value={terms}
							onChange={this.handleTermsChange}
							showInfo={true}
						/>
						<div className="submit-button-wrapper">
							<button
								className="btn btn-large waves-effect waves-light offset-s2"
								onClick={() => this.handleSubmit(isPrivateCustomer)}
								disabled={submitting || !this.isValid(isPrivateCustomer)}
							>
								<FormattedMessage
									id="direct.applicationform.submitbtn.text"
									description="Text for applicationform submitbtn"
									defaultMessage="Submit Application"
								/>
								<ArrowForward />
							</button>
							{submitting && <div className="loader" />}
							{submitting && <p className="submit-loader-text" />}
							{coSignerNotFound ? (
								<span className="span-error">
									<FormattedMessage
										id="direct.submit.error.coSignerNotFound"
										description="Text for medsøker ikke funnet / feil i oppslag"
										defaultMessage="Cannot find co-applicant / errors during search"
									/>
								</span>
							) : null}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ApplicationForm;
