import React from "react";
import { injectIntl, defineMessages } from "react-intl";
import PropTypes from "prop-types";

class FormattedOptionPairs extends React.Component {
	constructor(props) {
		super(props);
		const { intl, optionPairName, optionPairs } = props;
		const optionValueMessages = messages.option_values[optionPairName];
		const formattedValues = optionValueMessages
			? optionPairs.map(item => {
					let message = optionValueMessages[item.Value];

					if (message === undefined) {
						console.warn(
							`Message definition for option: '${item.Value}' in list '${optionPairName}' is missing"`
						);

						message = {
							id: `direct.optionvalues.${optionPairName}.${item.Value}`,
							defaultMessage: "{default}"
						};
					}

					item.Text = intl.formatMessage(message, { default: item.Text });
					return item;
			  })
			: optionPairs;
		this.state = {
			optionValues: formattedValues
		};
	}

	render() {
		return this.props.children(this.state.optionValues);
	}
}

FormattedOptionPairs.propTypes = {
	optionPairName: PropTypes.string.isRequired,
	optionPairs: PropTypes.array.isRequired
};

export const messages = {
	option_values: {
		CitizenTypeList: defineMessages({
			1: {
				id: "direct.optionvalues.CitizenTypeList.1",
				description: "Option value for citizenship - citizen, born in country",
				defaultMessage: "{default}"
			},
			2: {
				id: "direct.optionvalues.CitizenTypeList.2",
				description: "Option value for citizenship - citizen, born abroad",
				defaultMessage: "{default}"
			},
			3: {
				id: "direct.optionvalues.CitizenTypeList.3",
				description: "Option value for citizenship - not citizen, born abroad",
				defaultMessage: "{default}"
			}
		}),
		MaritalStatus: defineMessages({
			MARRIED: {
				id: "direct.optionvalues.MaritalStatus.MARRIED",
				description: "Option value for MaritalStatus - MARRIED",
				defaultMessage: "{default}"
			},
			COHABITANT: {
				id: "direct.optionvalues.MaritalStatus.COHABITANT",
				description: "Option value for MaritalStatus - COHABITANT",
				defaultMessage: "{default}"
			},
			DIVORCED: {
				id: "direct.optionvalues.MaritalStatus.DIVORCED",
				description: "Option value for MaritalStatus - DIVORCED",
				defaultMessage: "{default}"
			},
			SINGLE: {
				id: "direct.optionvalues.MaritalStatus.SINGLE",
				description: "Option value for MaritalStatus - SINGLE",
				defaultMessage: "{default}"
			},
			WIDOW: {
				id: "direct.optionvalues.MaritalStatus.WIDOW",
				description: "Option value for MaritalStatus - WIDOW",
				defaultMessage: "{default}"
			},
			OTHER: {
				id: "direct.optionvalues.MaritalStatus.OTHER",
				description: "Option value for MaritalStatus - OTHER",
				defaultMessage: "{default}"
			},
			OTHER_MARITAL_STATUS: {
				id: "direct.optionvalues.MaritalStatus.OTHER_MARITAL_STATUS",
				description: "Option value for MaritalStatus - OTHER_MARITAL_STATUS",
				defaultMessage: "{default}"
			}
		}),
		ProfessionalStatus: defineMessages({
			PERMANENT_EMPLOYEE_PRIVATE: {
				id: "direct.optionvalues.ProfessionalStatus.PERMANENT_EMPLOYEE_PRIVATE",
				description:
					"Option value for ProfessionalStatus - PERMANENT_EMPLOYEE_PRIVATE",
				defaultMessage: "{default}"
			},
			PERMANENT_EMPLOYEE_GOVERNMENTAL: {
				id: "direct.optionvalues.ProfessionalStatus.PERMANENT_EMPLOYEE_GOVERNMENTAL",
				description:
					"Option value for ProfessionalStatus - PERMANENT_EMPLOYEE_GOVERNMENTAL",
				defaultMessage: "{default}"
			},
			DISABILITY_BENEFIT: {
				id: "direct.optionvalues.ProfessionalStatus.DISABILITY_BENEFIT",
				description: "Option value for ProfessionalStatus - DISABILITY_BENEFIT",
				defaultMessage: "{default}"
			},
			RETIREE: {
				id: "direct.optionvalues.ProfessionalStatus.RETIREE",
				description: "Option value for ProfessionalStatus - RETIREE",
				defaultMessage: "{default}"
			},
			STUDENT: {
				id: "direct.optionvalues.ProfessionalStatus.STUDENT",
				description: "Option value for ProfessionalStatus - STUDENT",
				defaultMessage: "{default}"
			},
			OTHER_JOB_SITUATION: {
				id: "direct.optionvalues.ProfessionalStatus.OTHER_JOB_SITUATION",
				description:
					"Option value for ProfessionalStatus - OTHER_JOB_SITUATION",
				defaultMessage: "{default}"
			},
			UNEMPLOYED: {
				id: "direct.optionvalues.ProfessionalStatus.UNEMPLOYED",
				description: "Option value for ProfessionalStatus - UNEMPLOYED",
				defaultMessage: "{default}"
			},
			PERMANENT_EMPLOYEE: {
				id: "direct.optionvalues.ProfessionalStatus.PERMANENT_EMPLOYEE",
				description: "Option value for ProfessionalStatus - PERMANENT_EMPLOYEE",
				defaultMessage: "{default}"
			},
			SELF_EMPLOYED: {
				id: "direct.optionvalues.ProfessionalStatus.SELF_EMPLOYED",
				description: "Option value for ProfessionalStatus - SELF_EMPLOYED",
				defaultMessage: "{default}"
			},
			JOBSEEKER: {
				id: "direct.optionvalues.ProfessionalStatus.JOBSEEKER",
				description: "Option value for ProfessionalStatus - JOBSEEKER",
				defaultMessage: "{default}"
			}
		}),
		ResidentialStatus: defineMessages({
			OWN_HOUSE: {
				id: "direct.optionvalues.ResidentialStatus.OWN_HOUSE",
				description: "Option value for ResidentialStatus - OWN_HOUSE",
				defaultMessage: "{default}"
			},
			CONDOMINIUM: {
				id: "direct.optionvalues.ResidentialStatus.CONDOMINIUM",
				description: "Option value for ResidentialStatus - CONDOMINIUM",
				defaultMessage: "{default}"
			},
			RENTAL: {
				id: "direct.optionvalues.ResidentialStatus.RENTAL",
				description: "Option value for ResidentialStatus - RENTAL",
				defaultMessage: "{default}"
			},
			LIVING_WITH_PARENTS: {
				id: "direct.optionvalues.ResidentialStatus.LIVING_WITH_PARENTS",
				description: "Option value for ResidentialStatus - LIVING_WITH_PARENTS",
				defaultMessage: "{default}"
			},
			OTHER_LIVING_SITUATION: {
				id: "direct.optionvalues.ResidentialStatus.OTHER_LIVING_SITUATION",
				description:
					"Option value for ResidentialStatus - OTHER_LIVING_SITUATION",
				defaultMessage: "{default}"
			},
			NOT_OWN_HOUSE: {
				id: "direct.optionvalues.ResidentialStatus.NOT_OWN_HOUSE",
				description: "Option value for ResidentialStatus - NOT_OWN_HOUSE",
				defaultMessage: "{default}"
			}
		}),
		GrossYearlySalary: defineMessages({
			15000: {
				id: "direct.optionvalues.GrossYearlySalary.15000",
				description: "Option value for GrossYearlySalary - 15000",
				defaultMessage: "{default}"
			},
			65000: {
				id: "direct.optionvalues.GrossYearlySalary.65000",
				description: "Option value for GrossYearlySalary - 65000",
				defaultMessage: "{default}"
			},
			95000: {
				id: "direct.optionvalues.GrossYearlySalary.95000",
				description: "Option value for GrossYearlySalary - 95000",
				defaultMessage: "{default}"
			}
		})
	}
};

export default injectIntl(FormattedOptionPairs);
