import { useCallback } from "react";
import CalcRulesLib from "@ploy-lib/calc-rules";
import { DployFieldName } from "@ploy-lib/types";

type ElementEvents = "CHANGE" | "CLICK" | "BLUR";

export function useSelectedField(
	field: DployFieldName | null
): (event: ElementEvents) => void {
	const callback = useCallback(
		(event: ElementEvents) => {
			if (!field) {
				return;
			}
			switch (event) {
				case "CHANGE":
					CalcRulesLib.SelectedField.setChanged(field.namespace, field.name);
					break;
				case "CLICK":
					CalcRulesLib.SelectedField.setClicked(field.namespace, field.name);
					break;
				case "BLUR":
					CalcRulesLib.SelectedField.setBlur(field.namespace, field.name);
					break;
			}
		},
		[field]
	);

	return callback;
}
