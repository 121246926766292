import React, { useCallback, useState } from "react";
import { Info } from "@material-ui/icons";
import { Cancel } from "@material-ui/icons";
import { injectIntl } from "react-intl";
import { Rifm } from "rifm";
import { useNumberFormatter } from "@ploy-ui/form-fields/es/formatters/number";

function NumberField(props) {
	const {
		intl,
		info,
		errorText,
		emptyIsZero,
		label,
		box,
		value: numberValue,
		className,
		onChange: onNumberChange,
		min,
		max,
		allowZero,
		name,
		format: numberFormat,
		...formatProps
	} = props;

	const [infoVisible, setInfoVisible] = useState(false);

	const handleChange = useCallback(
		value => {
			const numValue =
				typeof value === "number" && Number.isNaN(value)
					? null
					: value
					? value
					: allowZero && value === 0
					? value
					: null;
			onNumberChange(name, numValue);
		},
		[allowZero, name, onNumberChange]
	);

	const toggleInfo = useCallback(
		e => {
			e.preventDefault();
			setInfoVisible(!infoVisible);
		},
		[infoVisible]
	);

	const {
		value,
		onChange,
		format,
		onBlur,
		onFocus,
		refuse,
		formatPrefix: prefix,
		formatSuffix: suffix
	} = useNumberFormatter(
		null,
		{
			...(intl.formats.number || intl.defaultFormats.number)[numberFormat],
			...formatProps
		},
		typeof numberValue === "number"
			? numberValue
			: numberValue == null || numberValue === ""
			? NaN
			: Number(numberValue),
		emptyIsZero,
		handleChange,
		undefined,
		undefined,
		min,
		max
	);

	const infoBox = info ? (
		<div className="info-box">
			<Cancel className="right" onClick={toggleInfo} />
			<h4>{label}</h4>
			<p>{info}</p>
		</div>
	) : null;

	return (
		<div className={className}>
			{infoVisible && infoBox}

			<label>
				{label}
				{info ? <Info className="waves-effect" onClick={toggleInfo} /> : null}
				<div
					className={`${box ? "input-box" : ""} ${errorText ? " error" : ""}`}
				>
					{prefix ? <span>{prefix}</span> : null}
					{/* @ts-ignore */}
					<Rifm
						refuse={refuse}
						value={value}
						onChange={onChange}
						format={format}
					>
						{rifmProps => (
							<input
								{...rifmProps}
								type="tel"
								onBlur={onBlur}
								onFocus={onFocus}
								className={
									(box ? "input-box-content browser-default" : "") +
									(errorText ? " invalid" : "")
								}
							/>
						)}
					</Rifm>
					{suffix ? <span>{suffix}</span> : null}
				</div>
			</label>
			<span className="span-error">{errorText}</span>
		</div>
	);
}

NumberField.displayName = "NumberField";

export default injectIntl(NumberField);
