import React from "react";

class TextField extends React.Component {
	static displayName = "TextField";

	state = {
		infoVisible: false
	};

	handleChange = e => this.props.onChange(this.props.name, e.target.value);

	render() {
		const { label, className, box, errorText } = this.props;
		return (
			<div className={className}>
				<label>
					{label}
					<div
						className={`${box ? "input-box" : ""} ${errorText ? " error" : ""}`}
					>
						<input
							type="text"
							className={
								(box ? "input-box-content browser-default" : "") +
								(this.props.errorText ? " invalid" : "")
							}
							value={this.props.value}
							onChange={this.handleChange}
						/>
					</div>
				</label>
				<span className="span-error">{errorText}</span>
			</div>
		);
	}
}

export default TextField;
