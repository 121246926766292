import React from "react";
import { injectIntl, defineMessages, FormattedMessage } from "react-intl";
import { ReactComponent as Check } from "../images/check.svg";
import denied from "../images/denied.png";

const Denied = props => <img src={denied} alt="denied" {...props} />;

const headers = defineMessages({
	NotFound: {
		id: "direct.result.notfound.header",
		description: "Header for result",
		defaultMessage: "Application not found"
	},
	WrongStatus: {
		id: "direct.result.wrongstatus.header",
		description: "Header for result",
		defaultMessage: "The application has already been submitted"
	},
	AutoRejected: {
		id: "direct.result.autorejected.header",
		description: "Header for result",
		defaultMessage: "Application has been rejected"
	},
	ManualUnderWriting: {
		id: "direct.result.manualunderwriting.header",
		description: "Header for result",
		defaultMessage: "Assessment"
	},
	AutoApproved: {
		id: "direct.result.autoapproved.header",
		description: "Header for result",
		defaultMessage: "The application has been approved"
	},
	SentToElectronicSignature: {
		id: "direct.result.senttoelectronicsignature.header",
		description: "Header for result",
		defaultMessage: "Signing"
	},
	SentToPaperSignature: {
		id: "direct.result.senttopapersignature.header",
		description: "Header for result",
		defaultMessage: "Signing"
	},
	[undefined]: {
		id: "direct.result.undefined.header",
		description: "Header for result",
		defaultMessage: "An error has occurred"
	}
});

const contents = defineMessages({
	NotFound: {
		id: "direct.result.notfound.content",
		description: "Content for result",
		defaultMessage: `We could not find the application. Please see that you have reached the correct address, or close the browser and try the link again.

If this does not work please contact us at phone 91505020 or email 05020@dnb.no`
	},
	WrongStatus: {
		id: "direct.result.wrongstatus.content",
		description: "Content for result",
		defaultMessage:
			"Your application has been submitted and is being processed by DNB. If you have any questions, please call 91505020 or send an e-mail to 05020@dnb.no"
	},
	AutoRejected: {
		id: "direct.result.autorejected.content",
		description: "Content for result",
		defaultMessage:
			"Dear Customer, We have now reviewed your application and, unfortunately, can not grant the loan. If you have information you think is important for our review, please call us on phone 91505020 for a closer chat. Best regards DNB Finans"
	},
	ManualUnderWriting: {
		id: "direct.result.manualunderwriting.content",
		description: "Content for result",
		defaultMessage:
			"Dear customer, we have received your application with case number. {applicationNumber}. We'll be back to you as soon as we've reviewed it. Best regards DNB Finans"
	},
	AutoApproved: {
		id: "direct.result.autoapproved.content",
		description: "Content for result",
		defaultMessage: `Dear Customer,

Your application with case number {applicationNumber} has been granted.
Documents have been sent to {salesperson} at {vendor}. Please contact with a reseller for signing documents.

Sincerely
DNB Finans`
	},
	SentToElectronicSignature: {
		id: "direct.result.senttoelectronicsignature.content",
		description: "Content for result",
		defaultMessage: `We have submitted the application for signing with BankID. You will shortly receive an SMS and email with information.

If you have questions about funding, or need help signing up, contact us at phone 91505020 or email 05020@dnb.no`
	},
	SentToPaperSignature: {
		id: "direct.result.senttopapersignature.content",
		description: "Content for result",
		defaultMessage: `We have sent your documents to your salesperson. Contact the salesperson for additional help.

If you have any questions about funding, contact us at phone 91505020 or email 05020@dnb.no`
	},
	[undefined]: {
		id: "direct.result.undefined.content",
		description: "Content for result",
		defaultMessage:
			"We're sorry, but an error has occurred. You can try to open the link again. If it does not work please contact us on phone 91505020 or email 05020@dnb.no"
	}
});

const links = defineMessages({
	NotFound: {
		id: "direct.result.notfound.link",
		description: "Link for result",
		defaultMessage: "Exit"
	},
	WrongStatus: {
		id: "direct.result.wrongstatus.link",
		description: "Link for result",
		defaultMessage: "Exit"
	},
	AutoRejected: {
		id: "direct.result.autorejected.link",
		description: "Link for result",
		defaultMessage: "Exit"
	},
	ManualUnderWriting: {
		id: "direct.result.manualunderwriting.link",
		description: "Link for result",
		defaultMessage: "Exit"
	},
	AutoApproved: {
		id: "direct.result.autoapproved.link",
		description: "Link for result",
		defaultMessage: "-"
	},
	SentToElectronicSignature: {
		id: "direct.result.senttoelectronicsignature.link",
		description: "Link for result",
		defaultMessage: "Go to Signing"
	},
	SentToPaperSignature: {
		id: "direct.result.senttopapersignature.link",
		description: "Link for result",
		defaultMessage: "Exit"
	},
	[undefined]: {
		id: "direct.result.undefined.link",
		description: "Link for result",
		defaultMessage: "Exit"
	}
});

const hrefs = defineMessages({
	NotFound: {
		id: "direct.result.notfound.linktarget",
		description: "Link target for result",
		defaultMessage: "https://www.dnb.no/en"
	},
	WrongStatus: {
		id: "direct.result.wrongstatus.linktarget",
		description: "Link target for result",
		defaultMessage: "https://www.dnb.no/en"
	},
	AutoRejected: {
		id: "direct.result.autorejected.linktarget",
		description: "Link target for result",
		defaultMessage: "https://www.dnb.no/en"
	},
	ManualUnderWriting: {
		id: "direct.result.manualunderwriting.linktarget",
		description: "Link target for result",
		defaultMessage: "https://www.dnb.no/en"
	},
	AutoApproved: {
		id: "direct.result.autoapproved.linktarget",
		description: "Link target for result",
		defaultMessage: "-"
	},
	SentToElectronicSignature: {
		id: "direct.result.senttoelectronicsignature.linktarget",
		description: "Link target for result",
		defaultMessage: "https://www.dnb.no/en"
	},
	SentToPaperSignature: {
		id: "direct.result.senttopapersignature.linktarget",
		description: "Link target for result",
		defaultMessage: "https://www.dnb.no/en"
	},
	[undefined]: {
		id: "direct.result.undefined.linktarget",
		description: "Link target for result",
		defaultMessage: "https://www.dnb.no/en"
	}
});

const icons = {
	AutoApproved: Check,
	AutoRejected: Denied,
	[undefined]: Check
};

class Result extends React.Component {
	static displayName = "Result";

	state = {
		signing: false
	};

	handlePrintSign = () => this.sign("paper");

	handleESign = () => this.sign("esign");

	sign = async solution => {
		const { appkey, status } = this.props;
		this.setState({ signing: true });

		const base = process.env.REACT_APP_API_URL;

		try {
			const response = await fetch(
				`${base}/submit/${appkey}/sign/${solution}`,
				{ methon: "POST" }
			);

			if (response.ok) {
				const data = await response.json();

				if (data.appStatus !== status)
					this.props.onStatusChange(data.appStatus);
			} else {
				this.props.onStatusChange();
			}
		} catch (e) {
			this.props.onStatusChange();
		} finally {
			this.setState({ signing: false });
		}
	};

	render() {
		const { data, status, onBack, intl } = this.props;
		const { signing } = this.state;

		const values = {
			applicationNumber: data.ApplicationNumber,
			customer: data.Customer && data.Customer.CustomerName,
			vendor: data.Vendor && data.Vendor.VendorName,
			salesperson: data.SalepersonName
		};

		const prefix = `direct.result.${(status || "").toLowerCase()}`;

		const linklabel = intl.formatMessage(
			links[status] || { id: `${prefix}.link` },
			values
		);
		const linkhref = intl.formatMessage(
			hrefs[status] || { id: `${prefix}.linktarget` },
			values
		);
		const header = intl.formatMessage(
			headers[status] || { id: `${prefix}.header` },
			values
		);
		const content = intl.formatMessage(
			contents[status] || { id: `${prefix}.content` },
			values
		);
		const IconComponent = icons[status];

		const hrefid = hrefs[status] ? hrefs[status].id : `${prefix}.linktarget`;

		const hasLink =
			linkhref.trim() && linkhref.trim() !== "-" && linkhref !== hrefid;
		// const canSign = status === "AutoApproved";
		const canSign = false;
		const canESign = data.CanSignWithBankIdDirectly;

		return (
			<div className="page-submit">
				<div className="main-content">
					<div className="header">
						{IconComponent && <IconComponent />}
						<h3>{header}</h3>
					</div>

					<div className="content">
						<p>{content}</p>
					</div>
				</div>

				<div className="extra-content">
					{hasLink && (
						<a href={linkhref} className="btn btn-large waves-effect gray">
							{linklabel}
						</a>
					)}
					{canSign && canESign && (
						<button
							className="btn btn-large waves-effect left"
							onClick={this.handleESign}
							disabled={signing}
						>
							<FormattedMessage
								id="direct.result.esign.button"
								description="Button for electronic signature"
								defaultMessage="Sign with BankID"
								values={values}
							/>
						</button>
					)}
					{canSign && (
						<button
							className={`btn btn-large waves-effect gray ${
								canESign ? "right" : ""
							}`}
							onClick={this.handlePrintSign}
							disabled={signing}
						>
							<FormattedMessage
								id="direct.result.printsign.button"
								description="Button for print signature"
								defaultMessage="Sign at dealership"
								values={values}
							/>
						</button>
					)}
					{signing && <div className="loader" />}
					{onBack && (
						<button
							className="btn btn-large waves-effect gray"
							onClick={onBack}
						>
							<FormattedMessage
								id="direct.result.back.button"
								description="Button for result"
								defaultMessage="Go back"
								values={values}
							/>
						</button>
					)}
				</div>
				<div className="clearfix" />
			</div>
		);
	}
}

export default injectIntl(Result);
