import { Financial } from "./financial";
import {
	EndBalanceBehaviors,
	CalculateEndBalanceInternal
} from "./endBalanceBehaviors";
import {
	DecisionTableTemplate,
	DecisionTableTemplateRow
} from "@ploy-lib/types";
import { WithGetSet } from "./types";

function CalculateTermAmount(data: WithGetSet<number>, periodNo = 0): number {
	const interest = data.get("Interest_" + periodNo)!;

	const termLength = data.get("TermLength")!;
	const loanAmount =
		periodNo === 0
			? data.get("LoanAmount")!
			: data.get("EndBalance_" + (periodNo - 1))!;
	const calcType = data.get("CalculationType") || 0;
	const loanType = data.get("LoanType") || 0;

	const periodDividedInterest = interest / 100 / (12 / termLength);

	const currentPaySerieTerms = data.get("TermsCount_" + periodNo)!;

	const currentPaySeriesEndBalance = data.get("EndBalance_" + periodNo)!;

	// Compute payment using current interest and the _remaining_ number of terms
	const pmtWithCurrentInterest = Financial.PMT(
		periodDividedInterest,
		currentPaySerieTerms,
		-loanAmount,
		currentPaySeriesEndBalance,
		calcType,
		loanType
	);

	return pmtWithCurrentInterest;
}

function CalculateEndBalance(data: WithGetSet<number>, periodNo = 0): number {
	return CalculateEndBalanceInternal(
		data,
		periodNo,
		data.get("Interest_" + periodNo)!
	);
}

function CalculateLimitTermAmount(
	amountType: string,
	data: WithGetSet<number | { [key: string]: string }[]>,
	allowNegativeValue: boolean | undefined = true
) {
	const limitDetails = data.get("LIMIT_DETAILS")! as {
		[key: string]: string;
	}[];

	const interest = data.get("Interest_0")! as number;
	const termLength = data.get("TermLength")! as number;
	let negativeValuesFlag = false;

	var totalPmt = 0;
	limitDetails.forEach(limitDetail => {
		const terms = Number(limitDetail.terms ?? 0);
		const periodDividedInterest = interest / ((100 * 12) / termLength);
		const amount =
			amountType === "TotalLimit"
				? Number(limitDetail.loanAmount ?? 0)
				: amountType === "ResidualLimit"
				? Number(limitDetail.loanAmount ?? 0) -
				  Number(limitDetail.usedAmount ?? 0)
				: 0;
		if (amount === 0) return;

		const endBalance = 0;
		const calctype = (data.get("CalculationType") as number) || 0;

		const pmt = Financial.PMT(
			periodDividedInterest,
			terms,
			-amount,
			endBalance,
			calctype
		);

		if (!allowNegativeValue && pmt < 0) {
			negativeValuesFlag = true;
			return;
		}
		totalPmt += pmt;
	});

	return negativeValuesFlag ? NaN : totalPmt;
}

function ExtractRowsFromDecisionTableTemplate(
	dtType: string,
	data: WithGetSet<DecisionTableTemplate[]>
) {
	const tableRows =
		data.get("DecisionTableTemplates")?.find(dt => dt.type === dtType)?.rows ??
		[];

	return tableRows;
}

function ExtractValueFromDecisionTableRow(
	columnName: string,
	row: DecisionTableTemplateRow | undefined
) {
	const value = row?.dynamicFields.find(
		df => df.category === columnName
	)?.value;

	return value;
}

export const FinancialUtils = {
	MapEndBalanceBehavior: function (
		endBalanceBehavior: keyof typeof EndBalanceBehaviors,
		data: WithGetSet<number>,
		periodNo = 0
	) {
		const candidateBehavior =
			EndBalanceBehaviors.hasOwnProperty(endBalanceBehavior) &&
			EndBalanceBehaviors[endBalanceBehavior];

		if (candidateBehavior) {
			return candidateBehavior(data, periodNo);
		}

		throw new Error(
			`Unknown end balance behavior ${endBalanceBehavior} for periodNo ${periodNo}`
		);
	},
	CalculateTermAmount,
	CalculateEndBalance,
	CalculateLimitTermAmount,
	ExtractRowsFromDecisionTableTemplate,
	ExtractValueFromDecisionTableRow
};
