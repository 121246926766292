import "./polyfills";
import "./jquery-global";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { EnvProvider } from "@ploy-lib/core";
import { TenantIntlProvider } from "@ploy-ui/tenants/TenantIntlProvider";

import {
	NetworkErrorBoundary,
	SubscriptionManager,
	PollingSubscription,
	DevToolsManager
} from "rest-hooks";
import { CacheProvider, NetworkManager } from "@rest-hooks/core";

import "materialize-css";
import "materialize-css/dist/css/materialize.css";
import "materialize-css/dist/js/materialize.js";
import "./styles/styles.scss";
import { HiddenInProductionTenantSelector } from "@ploy-ui/tenants/dev-tools";
import { ErrorPage } from "./ErrorPage";

const title =
	window.env.REACT_APP_TITLE != null &&
	window.env.REACT_APP_TITLE !== "REACT_APP_TITLE"
		? window.env.REACT_APP_TITLE
		: "Direktelån";

document.title = title;

const managers = [
	new NetworkManager(15 * 60 * 1000),
	new SubscriptionManager(PollingSubscription)
];

if (process.env.NODE_ENV !== "production")
	managers.unshift(new DevToolsManager());

ReactDOM.render(
	<EnvProvider envFilter={env => env.includes("apply")}>
		<CacheProvider managers={managers}>
			<Suspense fallback={() => "loading..."}>
				<NetworkErrorBoundary
					fallbackComponent={props => (
						<>
							<ErrorPage {...props} />
							<HiddenInProductionTenantSelector />
						</>
					)}
				>
					<TenantIntlProvider defaultLocale="nb-NO" defaultCurrency="NOK">
						<App />
						<HiddenInProductionTenantSelector />
					</TenantIntlProvider>
				</NetworkErrorBoundary>
			</Suspense>
		</CacheProvider>
	</EnvProvider>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
