/* eslint-disable react/style-prop-object */
import React from "react";
import {
	injectIntl,
	FormattedMessage,
	FormattedNumber,
	defineMessages
} from "react-intl";
import IconHeader from "./IconHeader";
import { ReactComponent as MinusOrange } from "../images/minus_orange.svg";
import { ReactComponent as PlusOrange } from "../images/plus_orange.svg";
import { ReactComponent as Loandetails } from "../images/loandetails.svg";
import { ReactComponent as Minus } from "../images/minus.svg";
import { ReactComponent as Plus } from "../images/plus.svg";
import SelectField from "./SelectField";
import Checkbox from "./Checkbox";

class LoanDetails extends React.Component {
	static displayName = "LoanDetails";

	static defaultProps = {
		data: {},
		fieldState: {},
		showCampaign: false,
		availableCampaigns: {},
		selectedCampaign: "",
		showVendorBuyBack: false
	};

	state = {
		detailsVisible: false
	};

	handleChange = (field, value) =>
		this.props.onChange(this.props.name, field, value);

	showHideDetails = () =>
		this.setState({ detailsVisible: !this.state.detailsVisible });

	render() {
		const {
			fieldState,
			errorMessage,
			maxRepaymentTermsLimit,
			calculating,
			intl
		} = this.props;
		const data = this.props.data || {};

		const paymentInsuranceEp = fieldState.paymentInsurance;
		return (
			<div>
				<div className="section section-header-wrapper">
					<IconHeader
						text={
							<FormattedMessage
								id="direct.loandetails.iconheader.text"
								description="Text for lånedetaljer iconheader"
								defaultMessage="Loan details"
							/>
						}
						icon={<Loandetails />}
					/>
				</div>

				<div className="divider" />
				<div className="loanDetails">
					<div className="row">
						<div className="col s12">
							<p className="calculation-text">
								<FormattedMessage
									id="direct.loandetails.p.calculationtext"
									description="Text for loandetails paragraph"
									defaultMessage="Purchase"
								/>
							</p>
							<div className="calculation">
								<div className="calculation-sum">
									<FormattedNumber value={data.CarPrice} format="currency" />
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<NumberSpinner
							className="col l6 s12"
							label={
								<FormattedMessage
									id="direct.loandetails.numberspinner.egenkapital.label"
									description="Label for egenkapital"
									defaultMessage="Equity"
								/>
							}
							value={fieldState.equity}
							name="equity"
							onChange={this.handleChange}
							step={1000}
							max={data.CarPrice}
							min={0}
							hideSliders={!data.AllowAdjustmentEquity}
						/>
						<NumberSpinner
							className="col l6 s12"
							label={
								<FormattedMessage
									id="direct.loandetails.numberspinner.nedbetalingstid.label"
									description="Label for nedbetalingstid"
									defaultMessage="Downpayment period"
								/>
							}
							value={fieldState.terms}
							name="terms"
							onChange={this.handleChange}
							step={1}
							max={maxRepaymentTermsLimit}
							min={1}
							hideSliders={!data.AllowAdjustmentNrOfTerms}
						/>
						{this.props.showCampaign &&
						this.props.availableCampaigns.length > 0 ? (
							<SelectField
								className="highlight input-field col s12 row"
								name="campaign"
								value={fieldState.campaign}
								label={
									<FormattedMessage
										id="direct.additionalinfo.selectedfield.Campaign.label"
										description="Label for campaign field"
										defaultMessage="Campaign"
									/>
								}
								options={this.props.availableCampaigns}
								onChange={this.handleChange}
								errorText={null}
								emptyValueAllowed={true}
							/>
						) : null}
					</div>

					{errorMessage && <div className="span-error">{errorMessage}</div>}

					<div>
						<MonthlyTermAmount
							calculating={calculating}
							data={data}
							intl={intl}
						/>
					</div>

					<div className="calculation-details-wrapper">
						<div
							className="calculation-details-text valign-wrapper col s12"
							onClick={this.showHideDetails}
						>
							{this.state.detailsVisible ? <MinusOrange /> : <PlusOrange />}
							{this.state.detailsVisible ? (
								<FormattedMessage
									id="direct.loandetails.div.calculationdetailswrapper.hide.text"
									description="Text for calculationdetailswrapper"
									defaultMessage="Hide details"
								/>
							) : (
								<FormattedMessage
									id="direct.loandetails.div.calculationdetailswrapper.show.text"
									description="Text for calculationdetailswrapper"
									defaultMessage="View details"
								/>
							)}
						</div>
						{this.state.detailsVisible ? <CalcDetails calcRes={data} /> : null}
					</div>
					{paymentInsuranceEp && (
						<div className="row">
							<Checkbox
								className="col s12 m6 input-box-wrapper left-align"
								radioClassName="col s8 m3 l3"
								name="PaymentInsurance"
								value={paymentInsuranceEp.IsActive}
								label={
									<FormattedMessage
										tagName="span"
										id="direct.loandetails.radiogroup.paymentInsurance.label"
										description="Label for possibility to choose payment protection insurance"
										defaultMessage="Payment protection insurance"
									/>
								}
								onChange={(_, value) =>
									this.handleChange("paymentInsurance", {
										...paymentInsuranceEp,
										IsActive: value
									})
								}
							/>
						</div>
					)}
					{this.props.showVendorBuyBack === true ? (
						<div className="row">
							<Checkbox
								className="col s12 m6 input-box-wrapper left-align"
								radioClassName="col s8 m3 l3"
								name="VendorBuyBack"
								value={fieldState.VendorBuyBack}
								label={
									<FormattedMessage
										tagName="span"
										id="direct.loandetails.radiogroup.VendorBuyBack.label"
										description="Label for possibility to choose that dealer has buy back guarantee"
										defaultMessage="Dealer has buy back guarantee"
									/>
								}
								onChange={this.handleChange}
								showInfo={false}
							/>
						</div>
					) : null}
				</div>
			</div>
		);
	}
}

export default injectIntl(LoanDetails);

const messages = defineMessages({
	monthlyFee1: {
		id: "direct.mounthlytermamount.span.monthlyFee1",
		description: "Text for mounthlytermamount span",
		defaultMessage: `Monthly amount {iterator, selectordinal, 
  one {#st}
  two {#nd}
  few {#rd}
  other {#th}
} stage`
	},
	monthlyFeeLast: {
		id: "direct.mounthlytermamount.span.monthlyFeeLast",
		description: "Text for mounthlytermamount span",
		defaultMessage: `Monthly amount {iterator, selectordinal, 
  one {#st}
  two {#nd}
  few {#rd}
  other {#th}
} stage`
	},
	termCount: {
		id: "direct.loandetails.span.termcount.text",
		description: "Text for termcount",
		defaultMessage: `{termCount, selectordinal, 
  one {# month}
 other {# months}
}`
	},
	termCountLast: {
		id: "direct.loandetails.span.termcount.textLast",
		description: "Text for termcount",
		defaultMessage: `{termCount, selectordinal, 
  one {# month}
 other {# months}
}`
	}
});

class MonthlyTermAmount extends React.Component {
	static displayName = "MounthlyTermAmount";

	getRenderedPaySerie(paySerie, monthlyFeeMessage, termCountMessage) {
		return (
			<div className="rectangle-monthlyFee bigger">
				<div className="row valign-wrapper monthlyFee">
					<div className="col s8">
						<span className="no-break">{monthlyFeeMessage}</span>
						<br />
						{termCountMessage != null && termCountMessage.trim() !== "" ? (
							<span className="no-break">{termCountMessage} </span>
						) : (
							<span />
						)}
					</div>
					<div className="col s4">
						<FormattedNumber value={paySerie.MonthlyAmount} format="currency" />
					</div>
				</div>
			</div>
		);
	}

	render() {
		const { calculating, data, intl } = this.props;

		const payseries = data.PaySeries || [{}];
		if (payseries.length > 1) {
			const renderedPaySeries = [];
			for (let i = 0; i < payseries.length - 1; ++i) {
				const paySerie = payseries[i];
				renderedPaySeries.push(
					this.getRenderedPaySerie(
						paySerie,
						intl.formatMessage(messages.monthlyFee1, {
							iterator: i + 1,
							termCount: paySerie.TermsCount
						}),
						intl.formatMessage(messages.termCount, {
							iterator: i + 1,
							termCount: paySerie.TermsCount
						})
					)
				);
			}

			const lastPaySerie = payseries[payseries.length - 1];
			renderedPaySeries.push(
				this.getRenderedPaySerie(
					lastPaySerie,
					intl.formatMessage(messages.monthlyFeeLast, {
						iterator: payseries.length,
						termCount: lastPaySerie.TermsCount
					}),
					intl.formatMessage(messages.termCountLast, {
						iterator: payseries.length,
						termCount: lastPaySerie.TermsCount
					})
				)
			);

			return (
				<div>
					{renderedPaySeries}
					<div className="col s4">
						{calculating && <div className="loader" />}
					</div>
				</div>
			);
		} else {
			return (
				<div className="rectangle-monthlyFee">
					<div className="row valign-wrapper monthlyFee">
						<div className="col s4">
							<FormattedMessage
								id="direct.monthlytermamount.span.monthlyFee2"
								description="Text for mounthlytermamount span"
								defaultMessage="Monthly amount"
								values={{ termCount: payseries[0].TermsCount }}
							/>
						</div>
						<div className="col s4">
							{calculating && <div className="loader" />}
						</div>
						<div className="col s4">
							<FormattedNumber
								value={payseries[0].MonthlyAmount}
								format="currency"
							/>
						</div>
					</div>
				</div>
			);
		}
	}
}

class NumberSpinner extends React.Component {
	static displayName = "NumberSpinner";

	static defaultProps = {
		step: 1,
		min: 0,
		max: Number.MAX_VALUE
	};

	increase = e => {
		e.preventDefault();
		if (this.props.value < this.props.max)
			this.props.onChange(
				this.props.name,
				Number(this.props.value) + this.props.step
			);
	};

	decrease = e => {
		e.preventDefault();
		if (this.props.value > this.props.min)
			this.props.onChange(
				this.props.name,
				Number(this.props.value) - this.props.step
			);
	};

	handleChange = e =>
		this.props.onChange(this.props.name, Number(e.target.value));

	render() {
		const { className, label, name, value, hideSliders } = this.props;

		const calculationSum = (
			<FormattedMessage
				id="direct.numberspinner.div.calculationsum"
				description="Text for numberspinner calculationsum"
				defaultMessage={` {years, plural, 
  =0 {}
  one {# yr. }
  other {# yrs. }
}  {months, plural, 
  =0 {}
  one {# mo. }
  other {# mos. }
} `}
				values={{
					months: value % 12,
					years: Math.floor(value / 12)
				}}
			/>
		);

		if (hideSliders) {
			return (
				<div className={className}>
					<p className="calculation-text">{label}</p>
					<div className="calculation">
						<div className="calculation-sum">
							{name === "terms" ? (
								calculationSum
							) : (
								<FormattedNumber value={value} format="currency" />
							)}
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div className={className}>
					<p className="calculation-text">{label}</p>
					<div className="calculation valign-wrapper">
						<div
							className="calculation-left-wrapper valign-wrapper"
							onClick={this.decrease}
						>
							<Minus className="calculation-left" />
						</div>
						<div className="calculation-middle">
							{name === "terms" ? (
								calculationSum
							) : (
								<FormattedNumber value={value} format="currency" />
							)}
						</div>
						<Plus className="calculation-right" onClick={this.increase} />
					</div>
					<div className="calculation range-field">
						<input
							type="range"
							min={this.props.min}
							max={this.props.max}
							step={this.props.step}
							value={this.props.value}
							onChange={this.handleChange}
						/>
					</div>
				</div>
			);
		}
	}
}

const CalcDetails = ({ calcRes }) => {
	const payseries = calcRes.PaySeries || [{}];

	const financingTypesMap = {
		Loan: (
			<FormattedMessage
				id="direct.calcdetails.financingTypesMap.loan"
				description="Text for calcdetails financingTypesMap"
				defaultMessage="Loan"
			/>
		),
		BoatLoan: (
			<FormattedMessage
				id="direct.calcdetails.financingTypesMap.boatloan"
				description="Text for calcdetails financingTypesMap"
				defaultMessage="Boat loan"
			/>
		)
	};

	const stages = [];
	if (payseries.length > 1) {
		for (let i = 0; i < payseries.length - 1; ++i) {
			const paySerie = payseries[i];
			stages.push(
				<Details
					key={i.toString()}
					label={
						<FormattedMessage
							id="direct.calcdetails.details.hasmultiplestages.label"
							description="Text for calcdetails hasmultiplestages"
							defaultMessage={`Nominal interest rate {stage, selectordinal, 
  one {#st}
  two {#nd}
  few {#rd}
  other {#th}
} stage`}
							values={{ stage: i + 1, termCount: paySerie.TermsCount }}
						/>
					}
					value={
						<FormattedNumber
							value={paySerie.Interest / 100}
							// eslint-disable-next-line react/style-prop-object
							style="percent"
							minimumFractionDigits={2}
						/>
					}
				/>
			);
		}

		const lastPaySeries = payseries[payseries.length - 1];
		stages.push(
			<Details
				key={stages.length.toString()}
				label={
					<FormattedMessage
						id="direct.calcdetails.details.hasmultiplestages.last"
						description="Text for calcdetails hasmultiplestages"
						defaultMessage={`Nominal interest rate {stage, selectordinal, 
  one {#st}
  two {#nd}
  few {#rd}
  other {#th}
} stage`}
						values={{
							stage: payseries.length,
							termCount: lastPaySeries.TermsCount
						}}
					/>
				}
				value={
					<FormattedNumber
						value={lastPaySeries.Interest / 100}
						// eslint-disable-next-line react/style-prop-object
						style="percent"
						minimumFractionDigits={2}
					/>
				}
			/>
		);
	} else {
		stages.push(
			<Details
				key={stages.length.toString()}
				label={
					<FormattedMessage
						id="direct.calcdetails.details.nominellrente.label"
						description="Text for calcdetails financiering"
						defaultMessage="Nominal interest rate"
					/>
				}
				value={
					<FormattedNumber
						value={calcRes.NominelInterest / 100}
						// eslint-disable-next-line react/style-prop-object
						style="percent"
						minimumFractionDigits={2}
					/>
				}
			/>
		);
	}

	return (
		<div className="calculation-details">
			<table>
				<tbody>
					<Details
						label={
							<FormattedMessage
								id="direct.calcdetails.details.finansiering.label"
								description="Text for calcdetails financiering"
								defaultMessage="Finance"
							/>
						}
						value={
							financingTypesMap[calcRes.FinancingType] || (
								<FormattedMessage
									id="direct.calcdetails.details.finansiering.financingtype"
									description="Text for calcdetails financingtype"
									defaultMessage="Loan"
								/>
							)
						}
					/>
					<Details
						label={
							<FormattedMessage
								id="direct.calcdetails.details.egenkapital.label"
								description="Text for calcdetails egenkapital"
								defaultMessage="Equity"
							/>
						}
						value={
							<FormattedNumber
								value={calcRes.Equity / calcRes.CarPrice}
								style="percent"
								minimumFractionDigits={2}
							/>
						}
					/>

					{stages}

					<Details
						label={
							<FormattedMessage
								id="direct.calcdetails.details.etablerings.label"
								description="Text for calcdetails etablerings"
								defaultMessage="Establishment and registration fee"
							/>
						}
						value={<FormattedNumber value={calcRes.Fees} format="currency" />}
					/>
					<Details
						label={
							<FormattedMessage
								id="direct.calcdetails.details.loansum.label"
								description="Text for calcdetails loansum"
								defaultMessage="Loan amount"
							/>
						}
						value={
							<FormattedNumber value={calcRes.LoanAmount} format="currency" />
						}
					/>
					<Details
						label={
							<FormattedMessage
								id="direct.calcdetails.details.effectivrente.label"
								description="Text for calcdetails effectivrente"
								defaultMessage="Effective interest rate"
							/>
						}
						value={
							<FormattedNumber
								value={calcRes.EffectiveInterest / 100}
								style="percent"
								minimumFractionDigits={2}
							/>
						}
					/>
					<Details
						label={
							<FormattedMessage
								id="direct.calcdetails.details.termingebyr.label"
								description="Text for calcdetails termingebyr"
								defaultMessage="Installment fees"
							/>
						}
						value={
							<FormattedNumber value={calcRes.TermFee} format="currency" />
						}
					/>
					{calcRes.PaymentInsurance?.TermAmount > 0 && (
						<Details
							label={
								<FormattedMessage
									id="direct.calcdetails.details.paymentInsuranceAmount.label"
									description="Text for calcdetails paymentInsuranceAmount"
									defaultMessage="Payment insurance amount"
								/>
							}
							value={
								<FormattedNumber
									value={calcRes.PaymentInsurance?.TermAmount}
									format="currency"
								/>
							}
						/>
					)}
					<Details
						label={
							<FormattedMessage
								id="direct.calcdetails.details.total.label"
								description="Text for calcdetails total"
								defaultMessage="Total amount to pay"
							/>
						}
						value={
							<FormattedNumber value={calcRes.TotalCost} format="currency" />
						}
					/>
					<Details
						label={
							<FormattedMessage
								id="direct.calcdetails.details.totalinclegenkapital.label"
								description="Text for calcdetails totalinclegenkapital"
								defaultMessage="Total amount to pay including equity"
							/>
						}
						value={
							<FormattedNumber
								value={calcRes.TotalCostInclEquity}
								format="currency"
							/>
						}
					/>
				</tbody>
			</table>
			<p>
				<FormattedMessage
					id="direct.calcdetails.details.paragraph"
					description="Text for calcdetails paragraph"
					defaultMessage="The terms and conditions assume that the loan documents are signed electronically. The financing is offered by DNB Bank ASA and requires security in the car."
				/>
			</p>
		</div>
	);
};

const Details = ({ label, value }) => (
	<tr>
		<th>{label}</th>
		<td>{value}</td>
	</tr>
);
