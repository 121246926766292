import TextField from "./TextField";
import React from "react";
import { FormattedMessage, injectIntl, defineMessages } from "react-intl";
import RadioGroup from "./RadioGroup";
import marked from "@ploy-lib/purify-marked";

export const messages = defineMessages({
	pepBusinessExplanation: {
		id: "direct.additionalinfo.buttongroup.PoliticallyExposedPersonBusiness.info",
		defaultMessage:
			"According to the Act on Preventing Money Laundering and Terrorist Financing (AML law), a politically exposed person means a natural person who is or who has been entrusted with prominent public functions as:\r\n- a head of State, head of government, minister, deputy or assistant minister;\r\n- a member of parliament;\r\n- a member of the governing bodies of political parties;\r\n- a member of supreme courts, constitutional courts or other corresponding judicial bodies whose \r\n- decisions are not subject to appeal, except in exceptional cases;\r\n- a member of courts of auditors or of the highest decision-making bodies which audit the \r\n- financial management of the State and are equivalent to national audit offices;\r\n- a member of the boards of directors of central banks;\r\n- an ambassador or chargé d’affaires;\r\n- an officer in the armed forces holding the rank of general or higher;\r\n- a member of the administrative, management or supervisory bodies of wholly state-owned \r\n- enterprises; or\r\n- a director, deputy director or member of the board of an international organisation;\r\n\r\nAccording to the AML law, family members of a politically exposed person means:\r\n- the spouse, or any partner considered by the national law of the country concerned equivalent \r\n- to the spouse, of a politically exposed person;\r\n- the children and their spouses, or abovementioned partners, of a politically exposed person, and\r\n- the parents of a politically exposed person;\r\n\r\nAccording to the AML law, associates of a politically exposed person means:\r\n- natural persons who are known to have joint beneficial ownership of a corporate entity, trader or legal arrangement, or any other close business relations, with a politically exposed person; and\r\n- natural persons who have sole beneficial ownership of a corporate entity, trader or legal arrangement which is known to have been set up for the benefit de facto of a politically exposed person\r\n",
		description: "explanation of PEP"
	},
	requiredInputText: {
		id: "direct.validation.requiredTextField",
		description: "Text for obligatorisk felt",
		defaultMessage: "Required field"
	},
	yesText: {
		id: "global.yes",
		description: "Yes",
		defaultMessage: "Yes"
	},
	noText: {
		id: "global.no",
		description: "No",
		defaultMessage: "No"
	},
	PoliticallyExposedPersonBusinessLabel: {
		id: "direct.additionalinfo.buttongroup.PoliticallyExposedPersonBusiness.label",
		description: "Label for politically exposed person",
		defaultMessage: "Yes/No"
	},
	PoliticallyExposedPersonBusinessLongText: {
		id: "direct.additionalinfo.buttongroup.PoliticallyExposedPersonBusiness.topinfo",
		description: "Information about politically exposed person for business",
		defaultMessage:
			"Are there politically exposed persons (PEP), or their close family member or associates, in key roles in the company? Key roles are, for example, signatory authority, CEO, board member, product responsible."
	}
});

class AdditionalCompanyInfo extends React.Component {
	static displayName = "AdditionalInfo";

	static defaultProps = {
		validation: {},
		data: {},
		fieldState: {},
		customer: "Customer",
		showRequiresPermit: false,
		showPoliticallyExposedPerson: false,
		showDueDay: false
	};

	handleChange = (field, value) =>
		this.props.onChange(this.props.name, field, value);

	render() {
		const { validation, fieldState, intl } = this.props;

		return (
			<div>
				<div className="additionalInfo">
					{this.props.showPoliticallyExposedPerson === true ? (
						<>
							<div className="row">
								<div
									style={{ textAlign: "left" }}
									dangerouslySetInnerHTML={{
										__html: marked(
											intl.formatMessage(
												messages.PoliticallyExposedPersonBusinessLongText
											)
										)
									}}
								></div>

								<RadioGroup
									className="col s12 m6 input-box-wrapper left-align"
									radioClassName="col s8 m3 l3"
									name="PoliticallyExposedPerson"
									id={this.props.name + "pep"}
									flexDirection="row"
									value={
										fieldState.PoliticallyExposedPerson == null
											? ""
											: fieldState.PoliticallyExposedPerson.toString()
									}
									label={intl.formatMessage(
										messages.PoliticallyExposedPersonBusinessLabel
									)}
									errorText={
										validation.PoliticallyExposedPerson === false ? (
											<FormattedMessage
												id="direct.loandetails.radiogroup.PoliticallyExposedPersonNotChosen.label"
												description="Label for politically exposed person option must be selected"
												defaultMessage="Whether person is politically exposed person must be answered"
											/>
										) : null
									}
									onChange={this.handleChange}
									options={[
										{
											label: intl.formatMessage(messages.yesText),
											value: true.toString()
										},
										{
											label: intl.formatMessage(messages.noText),
											value: false.toString()
										}
									]}
									info={marked(
										intl.formatMessage(messages.pepBusinessExplanation)
									)}
								/>
							</div>
							<div className="row">
								<TextField
									className="col s12 m6 input-box-wrapper left-align"
									box
									name="PoliticallyExposedPersonSource"
									value={fieldState.PoliticallyExposedPersonSource}
									label={
										<FormattedMessage
											id="direct.additionalinfo.textfield.PoliticallyExposedPersonSource.label"
											description="Label for politically exposed person source field."
											defaultMessage="Source of PEP"
										/>
									}
									onChange={this.handleChange}
									errorText={
										validation.PoliticallyExposedPersonSource === false
											? intl.formatMessage(messages.requiredInputText)
											: null
									}
								/>
							</div>
						</>
					) : null}

					{this.props.showRequiresPermit === true ? (
						<div className="row">
							<RadioGroup
								className="col s12 m6 input-box-wrapper left-align"
								radioClassName="col s8 m3 l3"
								name="RequiresPermit"
								id={this.props.name}
								flexDirection="row"
								value={
									fieldState.RequiresPermit == null
										? ""
										: fieldState.RequiresPermit.toString()
								}
								label={
									<FormattedMessage
										id="direct.loandetails.textfield.RequiresPermit.label"
										description="Label for requires permit"
										defaultMessage="Requires Permit"
									/>
								}
								errorText={
									validation.RequiresPermit === false ? (
										<FormattedMessage
											id="direct.loandetails.radiogroup.RequiresPermit.notchosen.label"
											description="Label for required permit option must be selected"
											defaultMessage="Whether permit is required must be answered"
										/>
									) : null
								}
								onChange={this.handleChange}
								options={[
									{
										label: intl.formatMessage(messages.yesText),
										value: true.toString()
									},
									{
										label: intl.formatMessage(messages.noText),
										value: false.toString()
									}
								]}
							/>
						</div>
					) : null}
					<div className="clearfix" />
				</div>
			</div>
		);
	}
}

export default injectIntl(AdditionalCompanyInfo);
