import React from "react";
import { injectIntl, defineMessages } from "react-intl";
import "materialize-css";
import $ from "jquery";
import clsx from "clsx";

class SelectField extends React.Component {
	static displayName = "SelectField";

	constructor(props) {
		super(props);
		this.selectWrapperRef = React.createRef();
		this.selectRef = React.createRef();
	}

	handleChange = e => this.props.onChange(this.props.name, e.target.value);

	mapOptions = item => (
		<option value={item.Value} key={item.Value}>
			{item.Text}
		</option>
	);

	render() {
		const {
			intl,
			options = [],
			className,
			errorText,
			emptyValueAllowed = false
		} = this.props;

		return (
			<div
				className={clsx(className, "select-field-wrapper")}
				id={"select-field-" + this.props.name}
				ref={this.selectWrapperRef}
			>
				<select
					ref={this.selectRef}
					name={this.props.name}
					value={this.props.value || ""}
					onChange={this.handleChange}
				>
					<option value="" disabled={!emptyValueAllowed}>
						{intl.formatMessage(messages.Choose)}
					</option>
					{options.map(this.mapOptions)}
				</select>
				<label>{this.props.label}</label>
				{errorText ? <span className="span-error">{errorText}</span> : null}
			</div>
		);
	}

	componentDidMount() {
		const select = this.selectRef.current;

		$(select).formSelect();
		if (this.props.errorText)
			$(this.selectWrapperRef.current).find("input").addClass("invalid");

		// MaterializeCss renders select elements as list items, need this hack to have the selects catch events
		// more info: https://github.com/Dogfalo/materialize/issues/1160
		$(select).on("change", this.handleChange);
	}

	componentDidUpdate() {
		if (this.props.errorText)
			$(this.selectWrapperRef.current).find("input").addClass("invalid");
		else $(this.selectWrapperRef.current).find("input").removeClass("invalid");
	}
}

const messages = defineMessages({
	Choose: {
		id: "field.select.choose",
		description: "Label for choose...",
		defaultMessage: "Choose..."
	}
});

export default injectIntl(SelectField);
