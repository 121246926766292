import { WithGetSet } from "./types";

const Annuity = {
	PMT: (rate: number, nper: number, pv: number, fv = 0) =>
		-(pv + fv) / ((1 - Math.pow(1 + rate, -nper)) / rate) + fv * rate,

	PV: (rate: number, nper: number, pmt: number, fv = 0) =>
		rate === 0
			? fv + pmt * nper
			: fv + (pmt - rate * fv) * ((1 - 1 / Math.pow(1 + rate, nper)) / rate),

	FV: (rate: number, nper: number, pmt: number, pv = 0) =>
		rate === 0
			? -pv - pmt * nper
			: -pv * Math.pow(1 + rate, nper) +
			  (pmt * (1 - Math.pow(1 + rate, nper))) / rate
};

const PaymentDue = {
	PMT: (rate: number, nper: number, pv: number, fv = 0) =>
		(Annuity.PMT(rate, nper, pv, fv) * 1) / (1 + rate),
	PV: (rate: number, nper: number, pmt: number, fv = 0) =>
		Annuity.PV(rate, nper, pmt, fv / (1 + rate)) * (1 + rate),
	FV: (rate: number, nper: number, pmt: number, pv = 0) =>
		Annuity.FV(rate, nper, pmt * (1 + rate), pv)
};

const Serial = {
	PMT: (rate: number, nper: number, pv: number, fv = 0) => -(pv + fv) / nper,
	PV: (rate: number, nper: number, pmt: number, fv = 0) => nper * pmt + fv,
	FV: (rate: number, nper: number, pmt: number, pv = 0) => -nper * pmt - pv,
	TotalPMTInclInterest: (rate: number, nper: number, pv: number, fv = 0) =>
		(rate * nper * (pv + fv + (pv - fv) / nper)) / 2 + (pv - fv)
};

export const Financial = {
	/**
	 *
	 * @param {termAmount} Target term amount
	 * @param {backCalcDiff} Difference between object price and sum of equity and other co-financed costs.
	 * @param {termLength}  monthly(1)/quarterly(3) or something else?
	 * @param {i} yearly nominal interest rate
	 * @param {n} number of terms
	 * @param {fv} future value
	 * @param {type}  arrears(0)/advance(1)
	 * @param {currentObjectPrice} the current object price
	 * @returns {} new object price
	 */
	CalculateObjectPrice: function (
		termAmount: number,
		backCalcDiff: number,
		termLength: number,
		i: number,
		n: number,
		fv: number,
		type: number,
		currentObjectPrice: number = 0
	) {
		var objectPrice =
			this.PV((i * termLength) / 1200, n, termAmount, fv, type) +
			(backCalcDiff || 0);

		// Assume we're rounding to no decimals, check if current ObjectPrice is within +/- 1 of given PMT.
		var oneStepDown =
			this.PV((i * termLength) / 1200, n, termAmount - 1, fv, type) +
			(backCalcDiff || 0);
		var oneStepUp =
			this.PV((i * termLength) / 1200, n, termAmount + 1, fv, type) +
			(backCalcDiff || 0);

		// If it is, return current price
		if (currentObjectPrice > oneStepDown && currentObjectPrice < oneStepUp)
			return currentObjectPrice;

		return objectPrice;
	},

	CalculateEffectiveInterest: function (data: WithGetSet<number>) {
		const payments: number[] = [];

		let includedEstFee =
			data.get("EstablishmentFee")! * data.get("InclStartupFee")!;
		let includedDocFee =
			data.get("DocumentFee")! * data.get("InclDocumentFee")!;
		let includedPVAddition = data.get("PVAddition") || 0;

		payments.push(
			-(
				data.get("StartBalance_0")! -
				includedEstFee -
				includedDocFee -
				includedPVAddition
			)
		);

		for (let i = 0; i < data.get("PayseriesCount")!; i++) {
			let netTermAmnt = data.get("NetTermAmount_" + i)!;
			let termFee = data.get("TermFee_" + i)!;

			// NetTermAmount does not include interest for serial loans (LoanType = 1)
			let interestRate =
				(data.get("Interest_" + i)! * data.get("TermLength")!) / 1200;
			let startBalance = data.get("StartBalance_" + i)!;

			for (let j = 0; j < data.get("TermsCount_" + i)!; j++)
				if (data.get("LoanType") === 1) {
					payments.push(
						Serial.TotalPMTInclInterest(
							interestRate,
							1,
							startBalance - j * netTermAmnt,
							startBalance - (j + 1) * netTermAmnt
						) + termFee
					);
				} else {
					payments.push(netTermAmnt + termFee);
				}
		}

		const endBalance = data.get(
			"EndBalance_" + (data.get("PayseriesCount")! - 1)
		)!;

		payments[payments.length - 1] = payments[payments.length - 1] + endBalance;

		const internalRate = this.IRR(payments);
		return 100 * (Math.pow(1 + internalRate, 12 / data.get("TermLength")!) - 1);
	},

	Round: function (amount: number, radix = 1, type = 0) {
		if (Math.abs(radix) < 10e-8) return amount;

		let roundFunc =
			Number(type) === 1
				? Math.ceil
				: Number(type) === -1
				? Math.floor
				: Math.round;

		return roundFunc(amount / radix) * radix;
	},

	/**
	 * Financial functions for use in CalcRules and wherever else we might want them.
	 * "Textbook"-implementations with interface somewhat* equivalent to Microsoft.VisualBasic.Financial namespace and Excel
	 */

	/**
	 *
	 * @param {numberOfPeriods} number of periods
	 * @param {payment} amount that will be payed, including interest, per period
	 * @param {presentValue} the present value of a series of future payments (loan amount)
	 * @param {futureValue} the future value after final payment (residual value) (Optional, default 0)
	 * @param {dueEndOfPeriod} true, if payments are due at the end of the period, false for beginning. (Optional, default true)
	 * @param {guess} estimated value to be returned (Optional, default 0.1, 10%)
	 * @returns {} the interest rate per period
	 * @throws Error if numberOfPeriods < 0
	 * @throws Error if inputs cause division by zero
	 * @throws Error if accurate rate could not be found, try another value for guess.
	 */
	RATE: function (
		numberOfPeriods: number,
		payment: number,
		presentValue: number,
		futureValue = 0,
		type = 0,
		guess = 0.1
	) {
		// https://stackoverflow.com/a/14576140

		const dueEndOfPeriod = !type;

		const lEvalRate = function (
			rate: number,
			nper: number,
			pmt: number,
			pv: number,
			dfv: number,
			end: boolean
		) {
			if (rate === 0) return pv + pmt * nper + dfv;
			const num1 = Math.pow(rate + 1, nper);
			const num2 = end ? 1 : 1 + rate;
			return pv * num1 + (pmt * num2 * (num1 - 1)) / rate + dfv;
		};

		if (numberOfPeriods < 0) {
			throw new Error("Number of periods must be greater than zero");
		}

		let rateUpperBoundary = guess;
		let lEvalRate1 = lEvalRate(
			rateUpperBoundary,
			numberOfPeriods,
			payment,
			presentValue,
			futureValue,
			dueEndOfPeriod
		);
		let rateLowerBoundary =
			lEvalRate1 <= 0 ? rateUpperBoundary * 2 : rateUpperBoundary / 2;
		let lEvalRate2 = lEvalRate(
			rateLowerBoundary,
			numberOfPeriods,
			payment,
			presentValue,
			futureValue,
			dueEndOfPeriod
		);

		for (let i = 0; i < 400; i++) {
			if (lEvalRate1 === lEvalRate2) {
				if (rateLowerBoundary > rateUpperBoundary) rateUpperBoundary -= 0.00001;
				else rateUpperBoundary -= -0.00001;

				lEvalRate2 = lEvalRate(
					rateUpperBoundary,
					numberOfPeriods,
					payment,
					presentValue,
					futureValue,
					dueEndOfPeriod
				);
				if (lEvalRate1 === lEvalRate2)
					throw new Error("Inputs will cause a divsion by zero");
			}

			const temporaryRate =
				rateLowerBoundary -
				((rateLowerBoundary - rateUpperBoundary) * lEvalRate2) /
					(lEvalRate2 - lEvalRate1);
			const lEvalRate3 = lEvalRate(
				temporaryRate,
				numberOfPeriods,
				payment,
				presentValue,
				futureValue,
				dueEndOfPeriod
			);

			if (Math.abs(lEvalRate3) < 0.0000001) return temporaryRate;

			lEvalRate1 = lEvalRate2;
			lEvalRate2 = lEvalRate3;
			rateUpperBoundary = rateLowerBoundary;
			rateLowerBoundary = temporaryRate;
		}

		throw new Error(
			"The maximum number of iterations has been exceeded, unable to calculate rate"
		);
	},

	PV: function (
		rate: number,
		nper: number,
		pmt: number,
		fv: number,
		type: string | number = 0
	) {
		var calc = Number(type) === 1 ? PaymentDue.PV : Annuity.PV;

		return calc(rate, nper, pmt, fv);
	},

	FV: function (
		rate: number,
		nper: number,
		pmt: number,
		pv: number,
		type: number | string = 0,
		loanType: number = 0
	) {
		var calc =
			loanType === 1
				? Serial.FV
				: Number(type) === 1
				? PaymentDue.FV
				: Annuity.FV;

		return calc(rate, nper, pmt, pv);
	},

	PMT: function (
		rate: number,
		nper: number,
		pv: number,
		fv: number = 0,
		type: number | string = 0,
		loanType: number = 0
	) {
		// Annuity formulas end up dividing by zero if interest is zero, this should be close enough to call it 0
		if (Math.abs(rate) < 10e-8) return (-pv - fv) / nper;
		var calc =
			loanType === 1
				? Serial.PMT
				: Number(type) === 1
				? PaymentDue.PMT
				: Annuity.PMT;
		return calc(rate, nper, pv, fv);
	},

	IRR: function (values: number[]) {
		let npvRes = (values: number[], rate: number) => {
			// NPV and derivative in a single pass
			var r = rate + 1;
			var npv = values[0];
			var derivative = 0;

			for (var i = 1; i < values.length; i++) {
				let rp = Math.pow(r, i);
				npv += values[i] / rp;
				derivative -= (i * values[i]) / (rp * r);
			}
			return { npv, derivative };
		};

		let resultRate = 0.01;

		const epsMax = 1e-10;

		let newRate, resultValue;

		for (let i = 0; i < 50; i++) {
			resultValue = npvRes(values, resultRate);
			newRate = resultRate - resultValue.npv / resultValue.derivative;

			if (Math.abs(newRate - resultRate) < epsMax) return resultRate;

			resultRate = newRate;
		}

		return NaN;
	},

	NPV: function (rate: number, cashflow: number[]) {
		let npv = 0;

		for (var j = 0; j < cashflow.length; j++)
			npv += cashflow[j] / Math.pow(1 + rate, j);

		return npv;
	},

	NPER: function (rate: number, pmt: number, pv: number, fv = 0, type = 0) {
		if (Math.abs(rate) < 10e-8) return -(pv + fv) / pmt;

		let num = pmt * (1 + rate * type) - fv * rate;

		let den = pv * rate + pmt * (1 + rate * type);

		return Math.log(num / den) / Math.log(1 + rate);
	}
};
