import React from "react";
import { FormattedMessage } from "react-intl";

import RadioGroup from "./RadioGroup";
import TextField from "./TextField";

export default class AddCoSigner extends React.Component {
	handleFieldChange = (field, value) => {
		const { name } = this.props;
		this.props.handleChange(name, field, value);
	};

	render() {
		const {
			toggleCoSigner,
			hasCosigner,
			validation,
			coSignerData: { customerNumber, email, phoneMobile }
		} = this.props;

		const requiredInputText = (
			<FormattedMessage
				id="direct.validation.requiredTextField"
				description="Text for obligatorisk felt"
				defaultMessage="Required field"
			/>
		);

		const invalidInputText = (
			<FormattedMessage
				id="direct.validation.invalidInput"
				description="Text for ugyldig feltverdi"
				defaultMessage="Invalid input"
			/>
		);

		return (
			<div className="additionalInfo">
				<div className="row">
					<span className="col no-padding">
						<FormattedMessage
							id="direct.coSigner.span.encouragingText"
							description="Text for å oppmuntre til å velge medsøker"
							defaultMessage="By adding a co-applicant, the loan is more likely to be granted"
						/>
					</span>
				</div>
				<RadioGroup
					className="add-cosigner left-align row"
					radioClassName="col s8 m2 l2"
					label={
						<FormattedMessage
							id="direct.coSigner.radiogroup.addcosigner.label"
							description="Label for adding coSigner"
							defaultMessage="Add co-applicant"
						/>
					}
					name="citizenType"
					id={this.props.name}
					value={hasCosigner}
					onChange={toggleCoSigner}
					options={[
						{
							label: (
								<FormattedMessage
									id="global.yes"
									description="Yes"
									defaultMessage="Yes"
								/>
							),
							value: true
						},
						{
							label: (
								<FormattedMessage
									id="global.no"
									description="No"
									defaultMessage="No"
								/>
							),
							value: false
						}
					]}
				/>
				{hasCosigner ? (
					<>
						<div className="row">
							<TextField
								className="col s12 m6 input-box-wrapper left-align"
								box
								name="customerNumber"
								value={customerNumber || ""}
								label={
									<FormattedMessage
										id="direct.coSigner.textfield.customerNumber.label"
										description="Label for personnummer til medsøker"
										defaultMessage="social security number"
									/>
								}
								onChange={(name, value) =>
									this.handleFieldChange(name, value ? value.toUpperCase() : "")
								}
								errorText={
									validation.customerNumber === false
										? customerNumber
											? invalidInputText
											: requiredInputText
										: null
								}
							/>
						</div>
						<div className="row">
							<TextField
								className="col s12 m6 input-box-wrapper left-align"
								box
								name="phoneMobile"
								value={phoneMobile || ""}
								label={
									<FormattedMessage
										id="direct.coSigner.textfield.phoneMobile.label"
										description="Label for mobilnummer til medsøker"
										defaultMessage="Phone number"
									/>
								}
								onChange={this.handleFieldChange}
								errorText={
									validation.phoneMobile === false
										? phoneMobile
											? invalidInputText
											: requiredInputText
										: null
								}
							/>
							<TextField
								className="col s12 m6 input-box-wrapper left-align"
								box
								name="email"
								value={email || ""}
								label={
									<FormattedMessage
										id="direct.coSigner.textfield.email.label"
										description="Label for email til medsøker"
										defaultMessage="Email"
									/>
								}
								onChange={this.handleFieldChange}
								errorText={
									validation.email === false
										? email
											? invalidInputText
											: requiredInputText
										: null
								}
							/>
						</div>
					</>
				) : null}
			</div>
		);
	}
}
