import React from "react";
import { FormattedMessage } from "react-intl";
import IconHeader from "./IconHeader";
import NumberField from "./NumberField";
import TextField from "./TextField";
import { ReactComponent as Money } from "../images/money.svg";

class EquityInfo extends React.Component {
	static displayName = "EquityInfo";

	static defaultProps = {
		data: {},
		fieldState: {}
	};

	handleChange = (field, value) =>
		this.props.onChange(this.props.name, field, value);

	render() {
		const { validation, fieldState, isLeasing, isPrivateCustomer } = this.props;
		const requiredInputText = (
			<FormattedMessage
				id="direct.validation.requiredTextField"
				description="Text for obligatorisk felt"
				defaultMessage="Required field"
			/>
		);

		return (
			<div>
				<div className="section section-header-wrapper">
					<IconHeader
						text={
							<FormattedMessage
								id="direct.equityinfo.iconheader.hederwrapper.text"
								description="Text for equityinfo iconheader"
								defaultMessage="Enter where equity comes from"
							/>
						}
						icon={<Money />}
					/>
				</div>
				<div className="divider" />
				<div className="equityInfo">
					<div className="col s12 row no-padding">
						{!isLeasing ? (
							<NumberField
								className="col s12 m6 input-box-wrapper left-align"
								name="innbytte"
								value={fieldState.innbytte}
								label={
									<FormattedMessage
										id="direct.equityinfo.numberfield.innbytte.label"
										description="Label for innbytte"
										defaultMessage="Trade-in"
									/>
								}
								format="currency"
								onChange={this.handleChange}
								box
								errorText={
									validation.innbytte === false ? requiredInputText : null
								}
							/>
						) : null}
						<NumberField
							className="col s12 m6 input-box-wrapper left-align"
							name="oppsparte"
							value={fieldState.oppsparte}
							label={
								<FormattedMessage
									id="direct.equityinfo.numberfield.oppsparte.label"
									description="Label for oppsparte"
									defaultMessage="Savings funds"
								/>
							}
							format="currency"
							onChange={this.handleChange}
							box
							errorText={
								validation.oppsparte === false ? requiredInputText : null
							}
						/>

						{isPrivateCustomer ? (
							<NumberField
								className="col s12 m6 input-box-wrapper left-align"
								name="egetSalg"
								value={fieldState.egetSalg}
								label={
									<FormattedMessage
										id="direct.equityinfo.numberfield.egetSalg.label"
										description="Label for egetSalg"
										defaultMessage="Own sale of used object"
									/>
								}
								format="currency"
								onChange={this.handleChange}
								box
								errorText={
									validation.egetSalg === false ? requiredInputText : null
								}
							/>
						) : null}

						<NumberField
							className="col s12 m6 input-box-wrapper left-align"
							name="annet"
							value={fieldState.annet}
							label={
								<FormattedMessage
									id="direct.equityinfo.numberfield.annet.label"
									description="Label for annet"
									defaultMessage="Other"
								/>
							}
							format="currency"
							onChange={this.handleChange}
							box
							errorText={validation.annet === false ? requiredInputText : null}
						/>

						{fieldState.innbytte ? (
							<TextField
								className="col s12 m6 right left-align"
								name="innbytteRegNo"
								value={fieldState.innbytteRegNo}
								label={
									<FormattedMessage
										id="direct.equityinfo.textfield.innbytteRegNo.label"
										description="Label for innbytteRegNo textfield"
										defaultMessage="Specify reg.nr. of trade-in car"
									/>
								}
								onChange={this.handleChange}
								errorText={
									validation.innbytteRegNo === false ? requiredInputText : null
								}
							/>
						) : null}

						{fieldState.egetSalg ? (
							<TextField
								className="col s12 m6 right left-align"
								name="egetSalgRegNo"
								value={fieldState.egetSalgRegNo}
								label={
									<FormattedMessage
										id="direct.equityinfo.textfield.egetSalgRegNo.label"
										description="Label for egetSalgRegNo textfield"
										defaultMessage="Specify reg.nr. of sold object"
									/>
								}
								onChange={this.handleChange}
								errorText={
									validation.egetSalgRegNo === false ? requiredInputText : null
								}
							/>
						) : null}

						{fieldState.annet ? (
							<TextField
								className="col s12 m6 right left-align"
								name="annetForklaring"
								value={fieldState.annetForklaring}
								label={
									<FormattedMessage
										id="direct.equityinfo.textfield.annet.label"
										description="Label for annet textfield"
										defaultMessage="Specify 'Other'"
									/>
								}
								onChange={this.handleChange}
								errorText={
									validation.annetForklaring === false
										? requiredInputText
										: null
								}
							/>
						) : null}
					</div>
					{validation.equitySum === false ? (
						<span className="span-error-equity col s12">
							<FormattedMessage
								id="direct.equityinfo.span.validation"
								description="Text for validation span"
								defaultMessage="The sum in the individual fields must match total equity."
							/>
						</span>
					) : null}
					<div className="clearfix" />
				</div>
			</div>
		);
	}
}

export default EquityInfo;
