import React from "react";
import { FormattedMessage } from "react-intl";
import IconHeader from "./IconHeader";
import { ReactComponent as Person } from "../images/person.svg";

class PersonInfo extends React.Component {
	static displayName = "PersonInfo";

	render() {
		const customer = this.props.customer || {};

		return (
			<div>
				<div className="section section-header-wrapper">
					<IconHeader
						text={
							<FormattedMessage
								id="direct.personinfo.iconheader.omdeg.text"
								description="Text for iconheader"
								defaultMessage="About you"
							/>
						}
						icon={<Person />}
					/>
				</div>
				<div className="divider" />
				<div className="personInfo">
					<table>
						<tbody>
							<tr>
								<th>
									<FormattedMessage
										id="direct.personinfo.table.th.navn"
										description="Text table th"
										defaultMessage="Name"
									/>
								</th>
								<td>{customer.CustomerName}</td>
							</tr>
							<tr>
								<th>
									<FormattedMessage
										id="direct.personinfo.table.th.addresse"
										description="Text table th"
										defaultMessage="Address"
									/>
								</th>
								<td>{customer.CustomerAddress}</td>
							</tr>
							<tr>
								<th>
									<FormattedMessage
										id="direct.personinfo.table.th.tlf"
										description="Text table th"
										defaultMessage="Phone"
									/>
								</th>
								<td>{customer.CustomerMobile}</td>
							</tr>
							<tr>
								<th>
									<FormattedMessage
										id="direct.personinfo.table.th.epost"
										description="Text table th"
										defaultMessage="E-mail"
									/>
								</th>
								<td>{customer.CustomerEmail}</td>
							</tr>
							{customer.CoCustomerName &&
							customer.CoCustomerName.trim().length > 0 ? (
								<tr>
									<th>
										<FormattedMessage
											id="direct.personinfo.table.th.medlåntaker"
											description="Text table th"
											defaultMessage="Co-applicant"
										/>
									</th>
									<td>{customer.CoCustomerName}</td>
								</tr>
							) : null}
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}

export default PersonInfo;
