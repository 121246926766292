import { Endpoint, EndpointInstance } from "@rest-hooks/core";
import { Tenant, Design } from "../types";

export const DEFAULT_DESIGN: Design = {
	name: "default",
	id: "default",
	muiTheme: t => t
};

export type TenantLoader = (key: string) => Promise<Tenant | null | undefined>;

export async function loadTenant(
	name: string,
	loader: TenantLoader
): Promise<Tenant | undefined> {
	let key = name && name.trim().toLowerCase();

	try {
		return (await loader(key)) ?? undefined;
	} catch (e: any) {
		if (process.env.NODE_ENV === "development") {
			throw new Error(`Tenant module missing: ${key}`);
		}

		if (key !== "demo") return loadTenant("demo", loader);
	}

	throw new Error(`Tenant module missing: ${key}`);
}

export const loadTenantSettings = new Endpoint(
	async function (
		this: EndpointInstance,
		{ tenantName, loader }: { tenantName: string; loader: TenantLoader }
	): Promise<Tenant> {
		const {
			designs = { DEFAULT_DESIGN_KEY: DEFAULT_DESIGN },
			getFormats = () => ({}),
			getMessages = async () => ({}),
			locales = [],
			images = {},
			formIcons = [],
			themeObject,
			designOverride,
			settings
		} = (await loadTenant(tenantName, loader)) ?? {};

		return {
			tenantName,
			designs,
			getFormats,
			getMessages,
			locales,
			images,
			formIcons,
			themeObject,
			designOverride,
			settings
		};
	},
	{
		key({ tenantName }) {
			return `LOAD tenant ${tenantName}`;
		},
		dataExpiryLength: Infinity
	}
);
