import React from "react";

class IconHeader extends React.Component {
	static displayName = "IconHeader";

	render() {
		return (
			<div className="valign-wrapper">
				{this.props.icon}
				<h5>{this.props.text}</h5>
			</div>
		);
	}
}

export default IconHeader;
